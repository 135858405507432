import { cx } from '../../utils/exports';
import useStyles from './styles';

interface Props {
  className?: string;
  color?: string;
  text?: string;
}

const ContentSeparator = ({ className, color, text }: Props) => {
  const classes = useStyles({ color });
  return (
    <div className={cx(classes.divider, className)}>
      {text && <span>{text}</span>}
    </div>
  );
};

export default ContentSeparator;
