import { createUseStyles } from 'react-jss';
import { color, getRem } from '@dovera/design-system';
import { FormVariant } from '../../../../../../types/proposals.types';

export default createUseStyles({
  insureeBar: (variant: FormVariant) => ({
    display: 'flex',
    position: 'relative',
    backgroundColor: color(variant, 100),
    borderRadius: getRem(5),
    padding: getRem(14),
    width: getRem(536),
    maxWidth: '100%',
    marginBottom: getRem(40),
    '& > div': {
      '&:first-child': {
        padding: `0 ${getRem(5)}`,
        '& img': {
          width: getRem(32),
          height: getRem(32),
          marginTop: -5,
        },
      },
      '&:nth-child(2)': {
        marginLeft: getRem(8),
        '& span': {
          display: 'block',
          '&:not(:last-child)': {
            marginBottom: getRem(8),
          },
        },
      },
    },
    '&.is-warning': {
      backgroundColor: color('error', 100),
    },
  }),
  statusIcon: {
    position: 'absolute',
    right: getRem(22),
  },
  notification: {
    width: 'fit-content',
  },
});
