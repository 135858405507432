import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../rootReducer';
import strings from '../../../../../../constants/strings';
import SafeHtml from '../../../../../../components/SafeHtml/SafeHtml';

const texts = strings.proposals.spa.new;

const Summary = () => {
  const { step4 } = useSelector(
    (state: RootState) => state.spaProposals.new.stepper,
  );
  return (
    <>
      <div className="mb-small">
        <b>{`${texts.summary.medicalHistory}: `}</b>
        <SafeHtml
          html={step4.description || strings.undefined}
          wrapper="span"
        />
      </div>
      <div className="no-mrg">
        <b>{`${texts.summary.anotherDiagnoses}: `}</b>
        {step4.diagnoses.map(
          (d, key) =>
            `${d.kod} - ${d.nazov}${
              step4.diagnoses.length > key + 1 ? ', ' : ''
            }`,
        )}
        {!step4.diagnoses.length && strings.undefined}
      </div>
    </>
  );
};

export default Summary;
