import { Modal } from '@dovera/design-system';
import React, { useEffect } from 'react';
import { ModalType } from '../../../../types/modal.types';
import useStyles from '../../Proposals.styles';
import Footer from './Footer';
import Body from './Body';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import { LoadingState } from '../../../../types';
import { useModalContext } from '../../../../hooks/useModalContext';
import { Dokument } from '../../../../types/models/Dokument';
import { saveDrugProposal } from '../../../../utils/drugProposals.utils';

interface Props {
  files: Dokument[] | null;
  handleCancel: () => void;
  modalID: ModalType;
}

const NotValidConditionsModal = ({ files, handleCancel, modalID }: Props) => {
  const { closeModal, isOpenModal } = useModalContext();
  const classes = useStyles({ proposalType: 'LN' });
  const { data, dataState } = useSelector(
    (state: RootState) => state.drugProposals.new.save,
  );

  useEffect(() => {
    if (!!data && dataState !== LoadingState.fetching) closeModal(modalID);
  }, [data, dataState, closeModal, modalID]);

  return (
    <Modal
      className={classes.modalNotValidConditions}
      footer={
        <Footer
          closeModal={() => closeModal(modalID)}
          handleCancel={handleCancel}
          onSubmit={() => saveDrugProposal(files)}
        />
      }
      header=""
      isVisible={isOpenModal(modalID)}
      onHide={() => closeModal(modalID)}
    >
      <Body />
    </Modal>
  );
};

export default NotValidConditionsModal;
