import { LoadingState, Nullable } from '.';
import { DocumentPayload } from './models/Dokument';
import {
  AvailableDoctorType,
  CheckType,
  NewProposalStep1,
  ProposalCheck,
  ProposalDiagnose,
  ProposalPOIData,
  StepStatus,
} from './proposals.types';

export type DrugProduct = {
  doplnokNazvu: string;
  kodAtcSkupiny: string;
  kodProduktPzs: string;
  nazovProduktPzs: string;
  preferovanyProdukt: {
    kodProduktPzs: string;
    nazovProduktPzs: string;
  };
};

export type IndicationLimatationData = {
  id: number;
  kodObmedzenia: string;
  kodProduktPzs: string;
  nazovObmedzenia: string;
};

export type DrugCnpProposalData = {
  datumPlatnostiOd: string;
  davkovanie: string;
  epikriza: string;
  id: number;
  jeKategorizovany: boolean;
  jednotkaMnozstva: string;
  kodAtcSkupiny: string;
  kodProduktPzs: string;
  kontraIndikacie: string[] | null;
  mnozstvo: number;
  typLiecby: string;
  zdovodnenieLiecby: string[] | null;
};

export interface GetListOfAvailableDoctorsQueries {}

export interface GetListOfAvailableDoctorsResponse {
  zoznamDostupnychLekarov: AvailableDoctorType[];
}

export interface GetProductsQueries {
  hladanyProdukt: string;
  odbornostLekara: string;
  odbornostNz: string;
}

export interface GetDiagnosesQueries {
  hladanaDiagnoza: string;
}

export interface GetProductsResponse {
  produkt: DrugProduct[];
}

export interface CheckProposalDataPayload {
  datumPlatnostiOd?: string;
  formularId?: string;
  idLekarZdravPrac?: number;
  jeCNP?: boolean;
  kodDiagnoza?: Nullable<string>;
  kodProduktPZS?: Nullable<string>;
  kontrolaTypy: CheckType[];
  odbornostLek?: string;
  odbornostNZ?: string;
  rodneCislo?: string;
}
export interface GetFormDataQueries {
  formularId: string;
  kodDiagnoza: string;
  kodProdukt: string;
  maMV: boolean;
  odbornostLek: string;
  odbornostNz: string;
  rodneCislo: string;
}

export interface GetFormDataResponse {
  cisloPredchadzajucehoLnCnp: string;
  udajIndikacnehoObmedzenia: IndicationLimatationData[];
  udajParLiekNavrhuCnp: DrugCnpProposalData;
}

export interface SaveProposalPayload {
  datumPlatnostiOd: string;
  davkovanie: string;
  epikriza: string;
  formularId: string;
  jednotkaMnozstva: string | null;
  kodATCSkupiny: string | null;
  kodDiagnoza: string;
  kodIO: string | null;
  kodProduktPZS: string;
  maKontraindikacie: boolean | null;
  maMV: boolean;
  mnozstvo: number;
  navrhujucaNZ: {
    id: number;
    kod: string;
    odbornost: string;
  };
  navrhujuciLekar: {
    id: number;
    kod: string;
    odbornost: string;
  };
  popisSposobuDavkovania: string;
  prilohy?: DocumentPayload[] | null;
  rodneCislo: string;
  zdovodnenieLiecby: string;
}

export interface SaveProposalResponse {
  chyba: string;
  evidencneCislo: string;
  jeCnp: boolean;
}

export interface DrugProposalsState {
  new: {
    data: {
      controls: {
        data: ProposalCheck[];
        error: string;
        errorInsuree: string;
        isLoadedTXSControls: boolean;
        isLoadingControl: CheckType[] | null;
      };
      doctors: {
        data: AvailableDoctorType[] | null;
        error: string | null;
        isLoading: boolean;
      };
      formData: {
        data: GetFormDataResponse | null;
        dataState: LoadingState;
        error: string | null;
        formId: string;
      };
      products: DrugProduct[];
    };
    isNotValidModalOpened: boolean;
    isReseted: boolean;
    save: {
      data: SaveProposalResponse | null;
      dataState: LoadingState;
      error: string | null;
    };
    stepper: {
      activeStep: number;
      step1: NewProposalStep1;
      step2: {
        insureeId: string;
        poiData: ProposalPOIData | null;
        stepStatus: StepStatus;
      };
      step3: {
        dateFrom: string;
        diagnose: ProposalDiagnose | null;
        dosage: string | null;
        dosageDescription?: string;
        epicrisis?: string;
        hasContraindication: Nullable<string>;
        hasIndication: Nullable<string>;
        hasMV: Nullable<string>;
        indicatorLimitation?: Nullable<{ code: string; label: string }>;
        product: Nullable<{ atc?: string | null; code: string; label: string }>;
        quantity: Nullable<number | string>;
        reason?: string;
        stepStatus: StepStatus;
      };
    };
  };
}

export enum DrugProposalField {
  Contraindication = 'Contraindication',
  DateFrom = 'DateFrom',
  Diagnose = 'Diagnose',
  Epicrisis = 'Epicrisis',
  IndicatorLimitationCode = 'IndicatorLimitationCode',
  MinisterException = 'MinisterException',
  Product = 'Product',
  QuantityDosage = 'QuantityDosage',
  Reason = 'Reason',
}

export type DrugProposalSteps = 0 | 1 | 2;

export enum FieldElementIdEnum {
  Contraindication = 'drugproposals--step3-contraindications',
  Diagnose = 'proposals--diagnose',
  Product = 'proposals--product-code',
}

export type FieldElementId =
  | FieldElementIdEnum.Contraindication
  | FieldElementIdEnum.Diagnose
  | FieldElementIdEnum.Product;
