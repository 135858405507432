import { useEffect } from 'react';
import { Input, Loader } from '@dovera/design-system';
import SafeHtml from '../../../../../components/SafeHtml/SafeHtml';
import strings from '../../../../../constants/strings';
import {
  FormVariant,
  ProposalPOIData,
} from '../../../../../types/proposals.types';
import InsureeBar from './InsureeBar/InsureeBar';
import {
  addressLowerCases,
  formatNameStr,
  formatPhone,
} from '../../../../../utils/strings.utils';
import { useDebounce } from '../../../../../hooks/useDebounce';

interface Props {
  errorElement?: JSX.Element;
  errorInsuree: string | null;
  insureeIdField: any;
  insureeLoading?: boolean;
  isInactiveInsuree: boolean;
  onChange: (value: string) => void;
  onReset: () => void;
  poiData: ProposalPOIData | null;
}

const texts = strings.proposals.spa.new;

const Form = ({
  errorElement,
  errorInsuree,
  insureeIdField,
  insureeLoading,
  isInactiveInsuree,
  onChange,
  onReset,
  poiData,
}: Props) => {
  const debouncedInsureeId = useDebounce(insureeIdField.input.value, 2000);
  useEffect(() => {
    if (
      debouncedInsureeId.length === 9 &&
      insureeIdField.input.value === debouncedInsureeId &&
      !insureeIdField.meta.error
    )
      onChange(debouncedInsureeId);
    // eslint-disable-next-line
  }, [debouncedInsureeId]);
  useEffect(() => {
    if (insureeIdField.input.value.length < 9 && poiData) onReset();
  }, [insureeIdField.input.value.length, onReset, poiData]);
  useEffect(() => {
    if (insureeIdField.input.value.length === 10)
      onChange(insureeIdField.input.value);
    // eslint-disable-next-line
  }, [insureeIdField.input.value]);
  const renderInsureeId = (
    <Input
      {...insureeIdField.input}
      addonsInside={insureeLoading}
      crossOrigin
      error={
        insureeIdField.meta.touched &&
        insureeIdField.meta.error &&
        !insureeLoading ? (
          <SafeHtml html={insureeIdField.meta.error} wrapper="span" />
        ) : (
          !insureeLoading && errorInsuree
        )
      }
      help={texts.helpers.insureeId}
      isDisabled={insureeLoading}
      isRequired
      label={texts.labels.patientInsureeId}
      maxLength={10}
      rightAddons={insureeLoading && <Loader size={24} />}
    />
  );
  const renderInsureeBar = poiData &&
    !insureeIdField.meta.error &&
    !insureeLoading &&
    !isInactiveInsuree && (
      <InsureeBar
        address={addressLowerCases(poiData.address, true)}
        dateOfBirth={poiData.dateOfBirth}
        mobile={formatPhone(poiData?.mobile || '', true)}
        name={`${formatNameStr(poiData.firstName)} ${formatNameStr(
          poiData.lastName,
        )}`}
        sex={poiData.sex}
        variant={FormVariant.Success}
      />
    );

  return (
    <>
      {renderInsureeId}
      {renderInsureeBar}
      {poiData && !insureeLoading && isInactiveInsuree && errorElement}
    </>
  );
};

export default Form;
