import axios, { AxiosResponse } from 'axios';
import { CheckProposalDataResponse } from '../types/spaProposals.types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CheckProposalDataPayload,
  DrugProduct,
  GetDiagnosesQueries,
  GetFormDataQueries,
  GetFormDataResponse,
  GetListOfAvailableDoctorsQueries,
  GetListOfAvailableDoctorsResponse,
  GetProductsQueries,
  GetProductsResponse,
  SaveProposalPayload,
  SaveProposalResponse,
} from '../types/drugProposals.types';
import { createQueryParams } from '../utils/api.utils';
import { Choice } from 'choices.js';
import store from '../store';
import { setProducts } from '../slices/drugProposals.slice';
import {
  GetDiagnosesListReponse,
  ProposalDiagnose,
} from '../types/proposals.types';

const API_CONTROLLER = `LnCnp`;

const API_URL = `${window.env?.config?.apiPath}/${API_CONTROLLER}/`;

export const getListOfAvailableDoctors = createAsyncThunk(
  'drugProposals/getListOfAvailableDoctors',
  async (queries: GetListOfAvailableDoctorsQueries) => {
    const { data }: AxiosResponse<GetListOfAvailableDoctorsResponse> =
      await axios.get(
        `${API_URL}DajZoznamDostupnychLekarov${createQueryParams({
          withoutEmpty: false,
          queryParams: {
            ...queries,
          },
        })}`,
      );
    return data;
  },
);

export const checkProposalData = createAsyncThunk(
  'drugProposals/checkDrugProposalData',
  async (payload: CheckProposalDataPayload) => {
    const url = `${API_URL}SkontrolujUdajeLiekovehoNavrhu`;
    const globalState = store.getState().drugProposals.new.stepper;
    const globalStateForm = store.getState().drugProposals.new.data.formData;
    const prevData: CheckProposalDataPayload = {
      kodDiagnoza: globalState.step3.diagnose?.kod || '',
      kontrolaTypy: [],
      idLekarZdravPrac: globalState.step1.doctor.id || 0,
      kodProduktPZS: globalState.step3.product?.code || '',
      odbornostLek: globalState.step1.doctor.expertise || '',
      odbornostNZ: globalState.step1.ambulance.expertise || '',
      rodneCislo: globalState.step2.insureeId || '',
      formularId: globalStateForm.formId || '',
    };
    const req = {
      ...prevData,
      ...payload,
      kontrolaTypy: payload.kontrolaTypy,
    };
    const { data }: AxiosResponse<CheckProposalDataResponse> = await axios.post(
      url,
      req,
    );
    return data;
  },
);

export const getProducts = async (
  queries: GetProductsQueries,
  signal: AbortSignal,
): Promise<Choice[]> => {
  const { data }: AxiosResponse<GetProductsResponse> = await axios.get(
    `${API_URL}DajZoznamProduktov${createQueryParams({
      withoutEmpty: false,
      queryParams: {
        ...queries,
      },
    })}`,
    {
      signal,
    },
  );
  const response = data?.produkt?.map((product: DrugProduct) => ({
    label: `${product.kodProduktPzs} - ${product.nazovProduktPzs}`,
    value: product.kodProduktPzs,
    preferredProducts: product.preferovanyProdukt,
  }));
  store.dispatch(setProducts({ products: data?.produkt || [] }));
  return response || [];
};

export const getDiagnosesList = async (
  queries: GetDiagnosesQueries,
  signal: AbortSignal,
): Promise<Choice[]> => {
  const { data }: AxiosResponse<GetDiagnosesListReponse> = await axios.get(
    `${API_URL}DajZoznamDiagnoz${createQueryParams({
      withoutEmpty: false,
      queryParams: {
        ...queries,
      },
    })}`,
    {
      signal,
    },
  );
  const response = data?.diagnozy?.map((diag: ProposalDiagnose) => ({
    label: `${diag.kod} - ${diag.nazov}`,
    value: diag.kod,
  }));
  return response || [];
};

export const getFormData = createAsyncThunk(
  'drugProposals/getFormData',
  async (queries: GetFormDataQueries) => {
    const url = `${API_URL}DajUdajePreFormularLiekovehoNavrhu${createQueryParams(
      {
        withoutEmpty: false,
        queryParams: {
          ...queries,
        },
      },
    )}`;
    const { data }: AxiosResponse<GetFormDataResponse> = await axios.get(url);
    return data;
  },
);

export const saveProposal = createAsyncThunk(
  'drugProposals/saveProposal',
  async (payload: SaveProposalPayload) => {
    const url = `${API_URL}UlozLnCnp`;
    const { data }: AxiosResponse<SaveProposalResponse> = await axios.post(
      url,
      payload,
    );
    return data;
  },
);
