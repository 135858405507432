import { LoadingState } from '../types';
import { DrugProposalsState } from '../types/drugProposals.types';
import { CheckType } from '../types/proposals.types';

export const DEFAULT_PRODUCT_CODE = 'XXXXXX';
export const DEFAULT_INDICATOR_LIMITATION_CODE = {
  label: 'Špeciálny kód indikačného obmedzenia pre výnimku revízneho lekára',
  value: 'I00000',
};

export const checkWithNoMessage: CheckType[] = [
  'JeKategorizovany',
  'JeCnp',
  'JeNavrh',
];

// Initial Redux State
export const drugProposalsInitialState: DrugProposalsState = {
  new: {
    data: {
      controls: {
        data: [],
        error: '',
        errorInsuree: '',
        isLoadedTXSControls: false,
        isLoadingControl: null,
      },
      doctors: {
        data: null,
        error: null,
        isLoading: false,
      },
      formData: {
        data: null,
        dataState: LoadingState.none,
        error: null,
        formId: '',
      },
      products: [],
    },
    isReseted: false,
    isNotValidModalOpened: false,
    save: {
      data: null,
      dataState: LoadingState.none,
      error: null,
    },
    stepper: {
      activeStep: 0,
      step1: {
        ambulance: {
          code: '',
          expertise: '',
          id: 0,
          name: '',
        },
        doctor: {
          code: '',
          email: '',
          expertise: '',
          id: 0,
          name: '',
        },
        stepStatus: null,
      },
      step2: {
        insureeId: '',
        poiData: null,
        stepStatus: null,
      },
      step3: {
        dateFrom: '',
        diagnose: null,
        dosage: 'Vlastné',
        dosageDescription: undefined,
        stepStatus: null,
        hasContraindication: undefined,
        hasIndication: null,
        hasMV: null,
        indicatorLimitation: undefined,
        product: null,
        quantity: null,
      },
    },
  },
};

// Mocked Redux State
export const drugProposalsMockedState: DrugProposalsState = {
  ...drugProposalsInitialState,
  new: {
    ...drugProposalsInitialState.new,
    stepper: {
      ...drugProposalsInitialState.new.stepper,
    },
  },
};
