import strings from '../../../../../constants/strings';
import { useSelector } from 'react-redux';
import {
  changeActiveStep,
  changeStepStatus,
  storeStep4,
} from '../../../../../slices/spaProposals.slice';
import { RootState } from '../../../../../rootReducer';
import { useField, useForm } from 'react-final-form-hooks';
import { Form } from './form';
import { Summary } from './summary';
import useStyles from '../../../Proposals.styles';
import { useAppDispatch } from '../../../../../hooks/useStore';
import { MIN_LENGTH_ANAMNESE } from '../../../../../constants/proposals';
import {
  getFirstMatch,
  isSqlInjection,
  replaceNewLineChars,
} from '../../../../../utils/strings.utils';
import { validationMsg } from '../../../../../utils/form.utils';
import { VALIDATION } from '../../../../../types/validation.types';
import regex from '../../../../../constants/regex';
import { useEffect } from 'react';
import { StepButtons, StepWrapper } from '../../common';
import { PROPOSAL_TYPES } from '../../../../../types/proposals.types';

const texts = strings.proposals.spa.new;

interface Props {
  onContinue: () => void;
}

const validate = (values: { [x: string]: any }) => {
  const errors: any = {};

  if (!values.anamnese || !replaceNewLineChars(values.anamnese))
    errors.anamnese = texts.inputErrors.anamnese;
  if (values.anamnese && values.anamnese.length < MIN_LENGTH_ANAMNESE)
    errors.anamnese = texts.inputErrors.anamneseLength;
  if (values.anamnese && isSqlInjection(values.anamnese))
    errors.anamnese = validationMsg(
      VALIDATION.SqlInjection,
      getFirstMatch(values.anamnese, regex.SQL_INJECTION),
    );
  if (
    values.anamnese &&
    (!replaceNewLineChars(values.anamnese) ||
      !values.anamnese.replace(/\s+/gi, '').replace(/\n+/gi, ''))
  )
    errors.anamnese = texts.inputErrors.incorrectValue;

  return {
    ...errors,
  };
};

const Step4 = ({ onContinue }: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles({ proposalType: 'KN' });
  const {
    data: {
      formData: { data },
    },
    isReseted,
    resetAfterFilled,
    stepper: {
      activeStep,
      step4: { stepStatus },
    },
  } = useSelector((state: RootState) => state.spaProposals.new);
  const { form, handleSubmit, values } = useForm({
    onSubmit: () => {
      dispatch(storeStep4({ description: values.anamnese }));
      dispatch(changeStepStatus({ status: 'completed', step: 4 }));
      setTimeout(() => {
        if (values.anamnese) onContinue();
      }, 100);
    },
    initialValues: {
      anamnese: '',
      additionalDiagnoses: '',
    },
    validate: (values) => validate(values),
  });
  useEffect(() => {
    if (isReseted || resetAfterFilled) form.restart();
  }, [form, isReseted, resetAfterFilled]);
  const anamneseField = useField('anamnese', form);
  const additionalDiagnosesField = useField('additionalDiagnoses', form);
  const renderContent = (
    <form className={classes.step4Form} onSubmit={handleSubmit}>
      <Form
        additionalDiagnosesField={additionalDiagnosesField}
        anamneseField={anamneseField}
        formData={data}
        onSelectedDiagnose={(values) =>
          dispatch(storeStep4({ diagnoses: values }))
        }
        reset={!!(isReseted || resetAfterFilled)}
      />
      <StepButtons onContinue={handleSubmit} />
    </form>
  );
  return (
    <StepWrapper
      activeStep={activeStep}
      changeActiveStep={changeActiveStep}
      // eslint-disable-next-line
      content={renderContent}
      step={4}
      stepStatus={stepStatus}
      summary={<Summary />}
      title={texts.stepTitles.step4}
      type={PROPOSAL_TYPES.SPA}
    />
  );
};

export default Step4;
