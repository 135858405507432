import strings from '../../../../../constants/strings';
import { hotjarMasking } from '../../../../../utils/strings.utils';

const texts = strings.proposals.spa.new;

const Summary = ({ ambulance, doctor }) => (
  <>
    <div className="mb-small">
      <b>{`${texts.labels.doctor}: `}</b>
      {hotjarMasking(`${doctor.name} (${doctor.code})`, 'b')}
    </div>
    <div className="mb-small">
      <b>{`${texts.labels.ambulance}: `}</b>
      {ambulance.code}
      {ambulance.name && ` (${ambulance.name})`}
    </div>
    <div className="no-mrg">
      <b>{`${texts.labels.email}: `}</b>
      {hotjarMasking(doctor.email || strings.undefined)}
    </div>
  </>
);

export default Summary;
