import strings from '../../../../../constants/strings';
import { useSelector } from 'react-redux';
import { changeActiveStep } from '../../../../../slices/drugProposals.slice';
import { RootState } from '../../../../../rootReducer';
import { Summary } from '../../common/PatientStep';
import { StepWrapper } from '../../common';
import { PROPOSAL_TYPES } from '../../../../../types/proposals.types';
import PatientForm from './PatientForm';

const texts = strings.proposals.spa.new;

interface Props {
  onCancel: () => void;
  onContinue: () => void;
}

const Step2 = ({ onCancel, onContinue }: Props) => {
  const { stepStatus } = useSelector(
    (state: RootState) => state.drugProposals.new.stepper.step1,
  );
  const { insureeId, poiData } = useSelector(
    (state: RootState) => state.drugProposals.new.stepper.step2,
  );
  const {
    stepper: { activeStep },
  } = useSelector((state: RootState) => state.drugProposals.new);

  return (
    <StepWrapper
      activeStep={activeStep}
      changeActiveStep={changeActiveStep}
      // eslint-disable-next-line
      content={
        <div id="drugProposal-step2">
          <PatientForm onCancel={onCancel} onContinue={onContinue} />
        </div>
      }
      step={2}
      stepStatus={stepStatus}
      summary={<Summary insureeId={insureeId} poiData={poiData} />}
      title={texts.stepTitles.step2}
      type={PROPOSAL_TYPES.Drugs}
    />
  );
};

export default Step2;
