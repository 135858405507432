import strings from '../../../../constants/strings';
import { GetProposalDetailResponse } from '../../../../types/spaProposals.types';
import { formatNameStr, hotjarMasking } from '../../../../utils/strings.utils';
import Section from './Section';

const texts = strings.proposals.spa.detail;

interface Props {
  navrhujuciLekar: GetProposalDetailResponse['navrhujuciLekar'];
}

const Doctor = ({ navrhujuciLekar }: Props) => (
  <Section
    rows={[
      {
        label: texts.labels.doctor,
        value: hotjarMasking(formatNameStr(navrhujuciLekar.lekar)),
      },
      {
        label: texts.labels.hospital,
        value: navrhujuciLekar.nemocnica
          ? formatNameStr(navrhujuciLekar.nemocnica)
          : strings.undefined,
      },
      {
        label: texts.labels.ambulance,
        value: formatNameStr(navrhujuciLekar.ambulancia) || strings.undefined,
      },
      {
        label: texts.labels.email,
        value: navrhujuciLekar.email
          ? hotjarMasking(navrhujuciLekar.email)
          : strings.undefined,
      },
    ]}
    title={texts.subtitles.proposalDoctor}
  />
);

export default Doctor;
