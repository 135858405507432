import { FieldRenderProps } from 'react-final-form-hooks';
import RadioElement from '../../../common/RadioElement/RadioElement';
import strings from '../../../../../../constants/strings';
import { useCallback, useEffect, useState } from 'react';
import useStyles from '../../../../Proposals.styles';
import { useAppDispatch } from '../../../../../../hooks/useStore';
import {
  resetControls,
  resetFormData,
  storeStep3,
} from '../../../../../../slices/drugProposals.slice';
import { checkProposalData } from '../../../../../../api/drugProposals';
import IconSearch from '../../../../../../components/CustomIcons/IconSearch';
import { Choice } from 'choices.js';
import Autocomplete from '../../../../../../components/Autocomplete';
import { ButtonLink, Input, Loader } from '@dovera/design-system';
import { useModalContext } from '../../../../../../hooks/useModalContext';
import { ModalType } from '../../../../../../types/modal.types';
import { canShowField } from '../../../../../../utils/drugProposals.utils';
import { DrugProposalField } from '../../../../../../types/drugProposals.types';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../rootReducer';
import { DEFAULT_INDICATOR_LIMITATION_CODE } from '../../../../../../constants/drugProposals';
import { cx } from '../../../../../../utils/exports';
import { FormApi } from 'final-form';
import { LoadingState } from '../../../../../../types';

interface Props {
  autocompleteIndicatorList: { label: string; value: string }[] | null;
  fieldIndicativeLimit: any;
  fieldRadio: FieldRenderProps<any, string>;
  form: FormApi<any>;
  indicatorLimitCount: number;
  indicatorList:
    | {
        label: string;
        value: string;
      }[]
    | null;
}

const texts = strings.proposals.drugs.new;

const getFirstIG = (
  indicatorList: {
    label: string;
    value: string;
  }[],
): {
  label: string;
  value: string;
} | null => {
  if (indicatorList.length === 1) return indicatorList[0];
  const defaultIG = indicatorList.find(
    (i) => i.value === DEFAULT_INDICATOR_LIMITATION_CODE.value,
  );
  if (defaultIG) return defaultIG;
  return null;
};

const MinisterException = ({
  autocompleteIndicatorList,
  fieldIndicativeLimit,
  fieldRadio,
  form,
  indicatorLimitCount,
  indicatorList,
}: Props) => {
  const modalContext = useModalContext();
  const dispatch = useAppDispatch();
  const firstIG =
    indicatorLimitCount === 1 ? getFirstIG(indicatorList || []) : null;
  const [isLoadingIndicativeLimatation, setIsLoadingIndicativeLimatation] =
    useState(false);
  const {
    data: {
      formData: { dataState },
    },
    stepper: {
      step3: { indicatorLimitation },
    },
  } = useSelector((state: RootState) => state.drugProposals.new);
  const [recommendedValue, setRecommendedValue] = useState<string | undefined>(
    undefined,
  );
  const classes = useStyles({ proposalType: 'LN' });
  useEffect(() => {
    if (firstIG && !indicatorLimitation) {
      dispatch(
        storeStep3({
          indicatorLimitation: {
            code: firstIG.value,
            label: firstIG.label,
          },
        }),
      );
    }
  }, [dispatch, firstIG, indicatorLimitation]);
  const handleChange = useCallback(
    (v: string) => {
      dispatch(resetControls({ controls: ['MaMV'] }));
      fieldIndicativeLimit.input.onChange('');
      form.resetFieldState('indicativeLimit');
      dispatch(
        storeStep3({
          indicatorLimitation: null,
        }),
      );
      dispatch(resetFormData({ withoutFormId: true }));
      setIsLoadingIndicativeLimatation(true);
      if (v === 'Y') dispatch(checkProposalData({ kontrolaTypy: ['MaMV'] }));
      dispatch(
        storeStep3({
          hasMV: v,
        }),
      );
      setTimeout(() => {
        setIsLoadingIndicativeLimatation(false);
      }, 500);
    },
    [dispatch, fieldIndicativeLimit.input, form],
  );
  const hasMinisterException = !!useSelector(
    (state: RootState) => state.drugProposals.new.data.controls.data,
  )?.some((d) => d.checkType === 'MaMV' && d.result === 'OK');
  const chooseIndication = useCallback(
    (code: string, label: string) => {
      setRecommendedValue(label);
      dispatch(
        storeStep3({
          indicatorLimitation: {
            code,
            label,
          },
        }),
      );
      modalContext.closeModal(ModalType.DP_RECOMMENDED);
    },
    [dispatch, modalContext],
  );
  const renderRadio = (
    <RadioElement
      field={fieldRadio}
      id="drugproposals--step3-permission"
      label={texts.labels.permission}
      onChange={handleChange}
      type="Má/Nemá"
      value={fieldRadio.input.value}
      wrapperClass={classes.radiocheckWrapper}
    />
  );
  const renderAutocomplete = (
    <Autocomplete
      {...fieldIndicativeLimit.input}
      addonsInside={!hasMinisterException}
      delay={200}
      error={
        fieldIndicativeLimit.meta.touched &&
        !fieldIndicativeLimit.meta.active &&
        fieldIndicativeLimit.meta.error &&
        fieldIndicativeLimit.meta.error
      }
      id="proposals--indicative-limit"
      isDisabled={hasMinisterException}
      isRequired
      label={texts.labels.indicativeLimit}
      minLength={0}
      noMarginBottom
      notFilterOptions
      onChange={(value: string) => {
        fieldIndicativeLimit.input.onChange(value);
        if (!value && indicatorLimitation?.code)
          dispatch(storeStep3({ indicatorLimitation: null }));
        setTimeout(() => {
          if (recommendedValue) setRecommendedValue(undefined);
        }, 300);
      }}
      onSelect={(option: Choice | any) => {
        if (option)
          setTimeout(() => {
            dispatch(
              storeStep3({
                indicatorLimitation: {
                  code: option.value,
                  label: option.label,
                },
              }),
            );
          }, 50);
      }}
      overwrittenValue={recommendedValue}
      rightAddons={
        !hasMinisterException && (
          <IconSearch
            className="clickable"
            id="proposals--indicative-limit--search"
          />
        )
      }
      source={() =>
        autocompleteIndicatorList && !recommendedValue
          ? Promise.resolve(autocompleteIndicatorList)
          : undefined
      }
      value={
        hasMinisterException
          ? DEFAULT_INDICATOR_LIMITATION_CODE.label
          : fieldIndicativeLimit.input.value
      }
    />
  );
  const defaultIndicatorLimitation = !!indicatorList?.length && firstIG && (
    <Input crossOrigin isDisabled value={`${firstIG.label}`} />
  );
  const renderIndicatorList = (
    <ButtonLink
      className={cx(classes.indicatorListBtn, 'is-absolute')}
      onClick={() =>
        modalContext.showModal(ModalType.DP_RECOMMENDED, {
          items: indicatorList,
          onSelect: chooseIndication,
          type: 'indicatorLimits',
        })
      }
    >
      {texts.buttons.indicatorList}
    </ButtonLink>
  );
  return (
    <div className={classes.drugsWrapper}>
      {renderRadio}
      {(isLoadingIndicativeLimatation ||
        dataState === LoadingState.fetching) && <Loader size={24} />}
      {fieldRadio.input.value === 'Y' &&
        canShowField(DrugProposalField.IndicatorLimitationCode) &&
        defaultIndicatorLimitation}
      {fieldRadio.input.value === 'N' &&
        canShowField(DrugProposalField.IndicatorLimitationCode) &&
        !isLoadingIndicativeLimatation &&
        (defaultIndicatorLimitation || (
          <div>
            {renderAutocomplete}
            {!!indicatorList?.length && renderIndicatorList}
          </div>
        ))}
    </div>
  );
};

export default MinisterException;
