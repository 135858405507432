export const systemConstants = {
  MAX_TEXTAREA_LETTERS: 750,
  MAX_MESSAGES_IN_THREAD: 15,
  MAX_MESSAGES_IN_THREAD_SEARCHING: 50,
  MAX_UPLOAD_SIZE: 20,
  MAX_UPLOAD_SIZE_SUM: 20,
  ALLOWED_MIME_TYPES: [
    'application/msword',
    'application/octet-stream',
    'application/pdf',
    'application/rtf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/zip',
    'image/jpeg',
    'image/png',
    'image/tiff',
    'image/gif',
    'text/csv',
    'text/plain',
    'text/rtf',
    'text/xml',
  ],
  MAX_INPUT_INT: 99999999,
};

export const URL_QUERIES = {
  expertise: 'nz',
  pzsType: 'kodtypzs',
};

export const dlekSections = [
  /** Hodnotiace parametre */
  'PZSHPKEI',
  /** Naklady pacientov */
  'PZSNAKL',
  'ZMLUVY',
  /** Statutar */
  'STAT',
  /** Profil */
  'PROF',
  /** Sprava pristupov */
  'MAST',
];

export enum FileErrorTypes {
  AllFilesSizeExceeds = 'AllFilesSizeExceeds',
  SingleFileSizeExceeds = 'SingleFileSizeExceeds',
  UnsupportedFileType = 'UnsupportedFileType',
}

export enum EPSections {
  Cnp = 'Cnp',
  DispZaz = 'DispZaz',
  Fkt = 'Fkt',
  Hosp = 'Hosp',
  Kn = 'Kn',
  Ln = 'Ln',
  Nkup = 'Nkup',
  Zuc = 'Zuc',
}
