import strings from '../../../../../constants/strings';
import { ProposalPOIData } from '../../../../../types/proposals.types';
import { hotjarMasking } from '../../../../../utils/strings.utils';

const texts = strings.proposals.spa.new;

interface Props {
  insureeId: string;
  poiData?: ProposalPOIData | null;
}

const Summary = ({ insureeId, poiData }: Props) => (
  <>
    <div className="mb-small">
      <b>{`${texts.labels.insureeId}: `}</b>
      {hotjarMasking(insureeId)}
    </div>
    <div className="no-mrg">
      <b>{`${texts.labels.patient}: `}</b>
      <span>{hotjarMasking(`${poiData?.firstName} ${poiData?.lastName}`)}</span>
    </div>
  </>
);

export default Summary;
