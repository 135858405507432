import { createContext, useState } from 'react';
import { ModalType } from '../types/modal.types';
import RecommendedModal from '../modals/RecommendedModal';
import { CancelActionModal } from '../modals/CancelActionModal';
import NotValidConditionsModal from '../pages/proposals/Modals/DrugProposal/NotValidConditionsModal';
import ParameterDetailModal from '../pages/parameters/common/modals/ParameterDetailModal';

interface ModalContextType {
  closeModal: (modalName: ModalType, props?: {}) => void;
  isOpenModal: (modalName: ModalType) => boolean;
  modalType: ModalType | null;
  props: any;
  showModal: (modalName: ModalType, props?: {}) => void;
}

const defaultModalContext: ModalContextType = {
  isOpenModal: () => false,
  modalType: null,
  showModal: () => {},
  closeModal: () => {},
  props: {},
};

export const ModalContext = createContext(defaultModalContext);

export const ModalProvider = ({ children }) => {
  const showModal = (modalType: ModalType, props = {}) => {
    showCurrentModal((previousState) => ({
      ...previousState,
      modalType,
      props: { ...props },
      isOpenModal: (modalTypeToCheck: ModalType) =>
        modalType === modalTypeToCheck,
    }));
  };

  const closeModal = (modalType: ModalType, props = {}) => {
    showCurrentModal((previousState) =>
      modalType === previousState.modalType
        ? {
            ...previousState,
            modalType: null,
            ...props,
            isOpenModal: () => false,
          }
        : previousState,
    );
  };

  const [currentModal, showCurrentModal] = useState<ModalContextType>({
    isOpenModal: () => false,
    modalType: null,
    props: {},
    showModal,
    closeModal,
  });

  return (
    <ModalContext.Provider value={currentModal}>
      {children}
      <RecommendedModal
        {...currentModal.props}
        modalID={ModalType.DP_RECOMMENDED}
      />
      <CancelActionModal
        {...currentModal.props}
        modalID={ModalType.CANCEL_ACTION}
      />
      <NotValidConditionsModal
        {...currentModal.props}
        modalID={ModalType.DP_NOT_VALID_CONDITIONS}
      />
      <ParameterDetailModal
        {...currentModal.props}
        modalID={ModalType.PARAMETERS_DETAIL}
      />
    </ModalContext.Provider>
  );
};

export const ModalConsumer = ModalContext.Consumer;
