export type SelectOption = {
  label: string;
  text?: string;
  value: string;
};

export type TableHead = {
  Header: string;
  accessor: string;
};

export enum Notification {
  Blue = 'blue',
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  White = 'white',
}

export enum RadioElement {
  No = 'N',
  Yes = 'Y',
}
