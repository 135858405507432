import { ReactNode, useRef } from 'react';
import useStyles from '../../Proposals.styles';
import StepHeader from '../../../../components/Stepper/StepHeader/StepHeader';
import StepSummary from '../../../../components/Stepper/StepSummary/StepSummary';
import StepContent from '../../../../components/Stepper/StepContent/StepContent';
import { cx } from '../../../../utils/exports';
import SafeHtml from '../../../../components/SafeHtml/SafeHtml';
import { useAppDispatch } from '../../../../hooks/useStore';
import { PROPOSAL_TYPES, StepStatus } from '../../../../types/proposals.types';

interface Props {
  activeStep: number;
  changeActiveStep: (payload: { step: number }) => void;
  content: ReactNode;
  step: number;
  stepStatus: StepStatus;
  summary: ReactNode;
  title: string;
  type: PROPOSAL_TYPES;
}

const StepWrapper = ({
  activeStep,
  changeActiveStep,
  content,
  step,
  stepStatus,
  summary,
  title,
  type,
}: Props) => {
  const classes = useStyles({ proposalType: 'KN' });
  const stepRef = useRef(null);
  const dispatch = useAppDispatch();
  const active: boolean = activeStep === step - 1;
  return (
    <>
      <StepHeader
        active={active}
        completed={!active && stepStatus === 'completed'}
        disabled={
          !active && stepStatus && !['completed', 'error'].includes(stepStatus)
        }
        editText="Upraviť"
        error={!active && stepStatus === 'error'}
        icon={<SafeHtml html={`&nbsp;${step}.`} wrapper="tspan" />}
        onHeaderClick={() => {
          if (stepStatus && ['completed', 'error'].includes(stepStatus))
            dispatch(changeActiveStep({ step: step - 1 }));
        }}
        stepRef={stepRef}
      >
        {title}
      </StepHeader>
      <StepSummary
        completed={
          !active && stepStatus
            ? ['completed', 'error'].includes(stepStatus)
            : false
        }
      >
        {summary}
      </StepSummary>
      <StepContent
        active={active}
        className={cx(active && classes.stepContent)}
        lastStep={type === PROPOSAL_TYPES.SPA ? step === 5 : step === 3}
      >
        {content}
      </StepContent>
    </>
  );
};

export default StepWrapper;
