import React from 'react';
import { Button, ButtonLayout, ButtonLink, Icon } from '@dovera/design-system';
import strings from '../../../../constants/strings';
import { useAppDispatch } from '../../../../hooks/useStore';
import { changeActiveStep } from '../../../../slices/drugProposals.slice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../rootReducer';
import {
  determineFieldByCheckType,
  determineStep,
  getModalErrorChecks,
  scrollToStep,
} from '../../../../utils/drugProposals.utils';
import { LoadingState } from '../../../../types';

const texts = strings.proposals.drugs.new.modals.notValid;

interface FooterProps {
  closeModal: () => void;
  handleCancel: () => void;
  onSubmit: () => void;
}

const Footer = ({ closeModal, handleCancel, onSubmit }: FooterProps) => {
  const dispatch = useAppDispatch();
  const { dataState } = useSelector(
    (state: RootState) => state.drugProposals.new.save,
  );
  const { controls } = useSelector(
    (state: RootState) => state.drugProposals.new.data,
  );

  const handleEdit = () => {
    const firstControl = getModalErrorChecks(controls?.data)[0];
    if (!firstControl) return;
    const step = determineStep(firstControl?.checkType || null);

    dispatch(changeActiveStep({ step }));
    closeModal();

    const field = determineFieldByCheckType(firstControl.checkType);
    setTimeout(() => scrollToStep(field), 500);
  };

  return (
    <ButtonLayout direction="horizontal">
      <div>
        <Button
          className="no-mrg-bottom text-space-right"
          isLoading={dataState === LoadingState.fetching}
          onClick={onSubmit}
        >
          {texts.buttons.submit}
        </Button>
        <Button
          className="no-mrg-bottom text-space-left"
          onClick={handleCancel}
          type="destructive-secondary"
        >
          {texts.buttons.cancel}
        </Button>
      </div>
      <ButtonLink className="no-mrg-bottom" onClick={handleEdit}>
        <Icon name="16-edit" size="medium" />
        {texts.buttons.edit}
      </ButtonLink>
    </ButtonLayout>
  );
};

export default Footer;
