import { jwtDecode } from 'jwt-decode';
import {
  AuthState,
  getAuthFromStorage,
  initialState,
} from '../slices/auth.slice';
import { AUTH_KEY } from '../constants';
import store from '../store';
import { history } from '../helpers/history';
import { userHasSection } from './app.utils';

/**
 * Description placeholder
 * @date 20. 2. 2023 - 10:39:33
 *
 * @returns {boolean}
 */
export const isJWTValid = (): boolean => {
  if (!window.sessionStorage.getItem(AUTH_KEY)) return false;
  // @ts-ignore
  const token = window.sessionStorage.getItem(AUTH_KEY);
  const currentSeconds = new Date().getTime() / 1000;
  // @ts-ignore
  return token && jwtDecode(token) && jwtDecode(token).exp >= currentSeconds;
};

export const getJWTParam = (param: string): string | number | null => {
  if (!window.sessionStorage.getItem(AUTH_KEY)) return null;
  // @ts-ignore
  const token = window.sessionStorage.getItem(AUTH_KEY);
  if (!token) return null;
  // @ts-ignore
  return jwtDecode(token)[param];
};

export const getToken = (): string | null => {
  if (!window.sessionStorage.getItem(AUTH_KEY)) return null;
  return window.sessionStorage.getItem(AUTH_KEY);
};

export const getUserId = (id?: number | null): number => Number(id);

export const getTokenState = (
  token: string,
  prevState: AuthState,
): AuthState => {
  if (!token) return initialState;
  const decodedToken = jwtDecode<any>(token);
  const sections = decodedToken?.dzp_user_role_section || '';
  return {
    ...prevState,
    token,
    pouzivatelId: getUserId(decodedToken?.dzp_user_id),
    vztahId: getUserId(decodedToken?.dzp_provider_id),
    isASOC: !!getUserId(decodedToken?.dzp_association),
    isSubjectAccess: !!getUserId(decodedToken?.dzp_payer_id),
    isSTAT: sections.includes('STAT'),
    isSUPR: sections.includes('SUPR'),
    hasHPKEI: sections.includes('PZSHPKEI'),
    hasPZSNAKL: sections.includes('PZSNAKL'),
    sekciaZmluvy: sections.includes('ZMLUVY'),
    refreshTokenInterval: getRefreshTokenInterval(token),
  };
};

export const isTokenExpired = (token?: string | null): boolean => {
  if (!token) return false;
  const decodedToken = jwtDecode<any>(token);
  const expirationTimestamp = decodedToken
    ? Number(decodedToken.exp) * 1000
    : 0;
  return expirationTimestamp < Date.now();
};

/**
 * Refresh token interval in miliseconds
 * get subtract of generated and expiration time of token
 * call refresh token endpoint 1 minute before token expires
 *
 * @param {?(string | null)} [jwt]
 * @returns {number}
 */
export const getRefreshTokenInterval = (jwt?: string | null): number => {
  const token = jwt || getToken();
  const now = Number.parseInt(Number(Date.now()).toString(), 10);
  const defaultInterval = 5 * 60 * 1000; // 5 minutes
  if (!token || !jwtDecode(token)?.exp) return defaultInterval;
  const minutesToExp = (Number(jwtDecode(token)?.exp) - now) / 60;
  if (minutesToExp < 5) return minutesToExp;
  const minutes = (Number(jwtDecode(token)?.exp) - now) / 60;
  if (minutes <= 5) return defaultInterval;
  return (minutes - 2) * 60 * 1000;
};

export const navigateAfterLogout = (url?: string): void => {
  if (!url) window.location.reload();
  history.navigate(url);
};

export const removeToken = (withReload?: boolean): void => {
  if (window.sessionStorage.getItem(AUTH_KEY)) {
    window.sessionStorage.removeItem(AUTH_KEY);
    store.dispatch({ type: 'resetStore' });
    if (withReload) window.location.reload();
  }
};

export const isTokenExpiring = (): boolean => {
  const token = getToken();
  if (!token) return false;
  const timeBeforeExpired = 1 * 60 * 1000; // 1 minute
  const decodedToken = jwtDecode<any>(token);
  const expirationTimestamp = decodedToken
    ? Number(decodedToken.exp) * 1000
    : 0;
  return expirationTimestamp < Date.now() + timeBeforeExpired;
};

export const noAccess = (orCondition?: boolean): boolean => {
  const token = getAuthFromStorage();
  const { hasHPKEI, hasPZSNAKL, isASOC, sekciaZmluvy } = store.getState().auth;
  const initialDataLoaded: boolean = !!store.getState().poskytovatel.isLoaded;
  const condition: boolean =
    !!token &&
    !hasHPKEI &&
    !hasPZSNAKL &&
    !sekciaZmluvy &&
    !isASOC &&
    initialDataLoaded &&
    !userHasSection('NAVZS') &&
    !userHasSection('CNP') &&
    !userHasSection('NKUP') &&
    !userHasSection('KAP') &&
    !userHasSection('PZSP');
  if (orCondition) return condition || orCondition;
  return condition;
};
