import { invert } from 'lodash';

export enum ModalType {
  CANCEL_ACTION = 'CA',
  DP_NOT_VALID_CONDITIONS = 'DPNVC',
  DP_RECOMMENDED = 'DPR',
  PARAMETERS_DETAIL = 'PD',
}

export const modalTypeMap = invert(ModalType);
