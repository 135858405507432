import { useEffect } from 'react';
import strings from '../../../../../constants/strings';
import { Button, ButtonLayout } from '@dovera/design-system';
import useStyles from '../../../Proposals.styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import IconCheckboxCircle from '../../../../../components/CustomIcons/IconCheckboxCircle';
import {
  changeActiveStep,
  storeStep5,
} from '../../../../../slices/spaProposals.slice';
import { useField, useForm } from 'react-final-form-hooks';
import { Form } from './form';
import { validationMsg } from '../../../../../utils/form.utils';
import { VALIDATION } from '../../../../../types/validation.types';
import { useAppDispatch } from '../../../../../hooks/useStore';
import { SPA_PROPOSAL_ESCORT_TYPE_AGE } from '../../../../../constants/proposals';
import { calculateAge } from '../../../../../utils/date.utils';
import { Export2PDF, StepWrapper } from '../../common';
import { PROPOSAL_TYPES } from '../../../../../types/proposals.types';

const texts = strings.proposals.spa.new;

interface Props {
  isVisibleConditionsModal: boolean;
  onContinue: () => void;
}

const validateForm = (values: { [x: string]: any }, insureeAge: number) => {
  const errors: any = {};

  if (!values.spaType) errors.spaType = validationMsg(VALIDATION.RequiredEmpty);
  if (!values.escort) errors.escort = validationMsg(VALIDATION.RequiredEmpty);
  if (
    values.escort === 'Y' &&
    insureeAge > SPA_PROPOSAL_ESCORT_TYPE_AGE &&
    !values.escortHelping
  )
    errors.escortHelping = validationMsg(VALIDATION.ProposalEscortReason);
  if (!values.mobility)
    errors.mobility = validationMsg(VALIDATION.RequiredEmpty);

  return errors;
};

const Step5 = ({ isVisibleConditionsModal, onContinue }: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles({ proposalType: 'KN' });
  const {
    data: {
      formData: { data },
    },
    isReseted,
    resetAfterFilled,
    save: { isLoading },
    stepper: {
      activeStep,
      step2: { poiData },
      step5: { stepStatus },
    },
  } = useSelector((state: RootState) => state.spaProposals.new);
  const { form, handleSubmit, values } = useForm({
    onSubmit: () => {
      onContinue();
    },
    initialValues: {
      escort: undefined,
      escortHelping: undefined,
      mobility: undefined,
      spaType: data?.udajDetIndSkup?.kodMieraUhradyZS === 'S' ? 'A' : undefined,
    },
    validate: (valuesToValid: any) =>
      validateForm(valuesToValid, calculateAge(poiData?.dateOfBirth || '')),
  });
  useEffect(() => {
    if (isReseted || resetAfterFilled) form.restart();
  }, [form, isReseted, resetAfterFilled]);
  const spaTypeField = useField('spaType', form);
  const escortField = useField('escort', form, (value) => {
    if (value === 'N' && !!values.escortHelping)
      form.change('escortHelping', undefined);
  });
  const escortHelpingField = useField('escortHelping', form);
  const mobilityField = useField('mobility', form);
  useEffect(() => {
    if (data?.udajDetIndSkup?.kodMieraUhradyZS === 'S') {
      dispatch(storeStep5({ spaType: 'A' }));
      spaTypeField.input.value = 'A';
    }
    // eslint-disable-next-line
  }, [data, dispatch]);
  useEffect(() => {
    if (values.escort && values.spaType && values.mobility) {
      dispatch(
        storeStep5({
          escort: values.escort,
          helpingEscort: values.escortHelping,
          spaType: values.spaType,
          mobility: values.mobility,
        }),
      );
    }
  }, [
    dispatch,
    values.escort,
    values.escortHelping,
    values.mobility,
    values.spaType,
  ]);
  const renderPoints = (
    <div className={classes.proposalPoints}>
      {texts.mustKnowBeforeSubmit.map((t, key) => (
        <div key={`proposalPoints--${key}`}>
          <IconCheckboxCircle id={`proposalPoint--icon--${key}`} />
          <span>{t}</span>
        </div>
      ))}
    </div>
  );
  const renderButtons = (
    <ButtonLayout direction="horizontal">
      <Button
        isDisabled={isLoading}
        isLoading={!isVisibleConditionsModal && isLoading}
        onClick={() => handleSubmit()}
      >
        {texts.buttons.submit}
      </Button>
      <Export2PDF />
    </ButtonLayout>
  );
  const renderContent = (
    <form onSubmit={handleSubmit}>
      <Form
        escortField={escortField}
        escortHelpingField={escortHelpingField}
        formData={data}
        insureeData={poiData}
        mobilityField={mobilityField}
        spaTypeField={spaTypeField}
      />
      {renderPoints}
      {renderButtons}
    </form>
  );
  const renderSummary = <div />;
  return (
    <StepWrapper
      activeStep={activeStep}
      changeActiveStep={changeActiveStep}
      // eslint-disable-next-line
      content={renderContent}
      step={5}
      stepStatus={stepStatus}
      summary={renderSummary}
      title={texts.stepTitles.step5}
      type={PROPOSAL_TYPES.SPA}
    />
  );
};

export default Step5;
