import { Dokument } from '../../types/models/Dokument';
import { toBase64 } from '../../utils/file.utils';

export const uploadHandler = async (files: File[]) => {
  const filesToUpload: any = [];
  const temp: Dokument[] = [];
  files.forEach((f) => {
    filesToUpload.push(toBase64(f));
    temp.push({
      id: 0,
      jeKomprimovany: f.type.includes('zip'),
      nazov: f.name,
      obsah: '',
      typ: f.type,
      velkost: f.size,
    });
  });
  const filePaths = await Promise.all(filesToUpload);
  return filePaths.map((base64, key) => ({
    ...temp[key],
    obsah: base64,
  }));
};
