import useStyles from '../../Proposals.styles';
import { Grid, GridCol, Skeleton } from '@dovera/design-system';
import { cx } from '../../../../utils/exports';

const SkeletonLoaderContent = () => {
  const classes = useStyles({ proposalType: 'KN' });
  const renderFirstRow = (
    <Grid>
      <GridCol size={6}>
        <h4 className="mb-small">
          <Skeleton width="20%" />
        </h4>
        <span className="d-block mb-small">
          <Skeleton width="30%" />
        </span>
        <span className="d-block mb-small">
          <Skeleton width="25%" />
        </span>
        <span className="d-block mb-small">
          <Skeleton width="40%" />
        </span>
        <span className="d-block mb-small">
          <Skeleton width="30%" />
        </span>
      </GridCol>
      <GridCol size={6}>
        <h4 className="mb-small">
          <Skeleton width="40%" />
        </h4>
        <span className="d-block mb-small">
          <Skeleton width="50%" />
        </span>
        <span className="d-block mb-small">
          <Skeleton width="45%" />
        </span>
        <span className="d-block mb-small">
          <Skeleton width="60%" />
        </span>
        <span className="d-block mb-small">
          <Skeleton width="30%" />
        </span>
      </GridCol>
    </Grid>
  );
  const renderSecondRow = (
    <Grid>
      <GridCol size={6}>
        <div className={cx(classes.sectionWithBorder, classes.contentSection)}>
          <h4 className="mb-small">
            <Skeleton width="70%" />
          </h4>
          <span className="d-block mb-small">
            <Skeleton width="50%" />
          </span>
          <span className="d-block mb-small">
            <Skeleton width="60%" />
          </span>
          <span className="d-block mb-xlarge">
            <Skeleton width="30%" />
          </span>
          <span className="d-block mb-xlarge">
            <Skeleton width="20%" />
          </span>
        </div>
      </GridCol>
      <GridCol size={6}>
        <h4 className="mb-small">
          <Skeleton width="50%" />
        </h4>
        <span className="d-block mb-small">
          <Skeleton width="40%" />
        </span>
        <span className="d-block mb-small">
          <Skeleton width="25%" />
        </span>
        <span className="d-block mb-xlarge">
          <Skeleton width="35%" />
        </span>
        <h4 className="mb-small">
          <Skeleton width="30%" />
        </h4>
        <span className="d-block mb-small">
          <Skeleton width="30%" />
        </span>
        <span className="d-block mb-small">
          <Skeleton width="30%" />
        </span>
        <span className="d-block mb-xlarge">
          <Skeleton width="35%" />
        </span>
      </GridCol>
    </Grid>
  );
  return (
    <>
      {renderFirstRow}
      {renderSecondRow}
    </>
  );
};

export default SkeletonLoaderContent;
