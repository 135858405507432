import { Input, Textarea } from '@dovera/design-system';
import useStyles from '../../../../Proposals.styles';
import { FieldRenderProps } from 'react-final-form-hooks';
import strings from '../../../../../../constants/strings';
import { useAppDispatch } from '../../../../../../hooks/useStore';
import { storeStep3 } from '../../../../../../slices/drugProposals.slice';
import RemainingChars from '../../../../../../components/RemainingChars/RemainingChars';
import { systemConstants } from '../../../../../../constants/systemConstants';
import SafeHtml from '../../../../../../components/SafeHtml/SafeHtml';

const { labels } = strings.proposals.drugs.new;

interface Props {
  dosageDescriptionField: FieldRenderProps<string, string>;
  dosageField: FieldRenderProps<string, string>;
  quantityField: FieldRenderProps<string | number, string>;
  storedValues: {
    dosage: string;
    dosageDescription: string;
    quantity: number;
  };
}

const QuantityDosage = ({
  dosageDescriptionField,
  dosageField,
  quantityField,
  storedValues,
}: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles({ proposalType: 'LN' });
  const renderQuantity = (
    <Input
      {...quantityField.input}
      crossOrigin
      error={
        quantityField.meta.touched &&
        !!quantityField.meta.error &&
        quantityField.meta.error
      }
      id="drugsQuantity"
      inputType="number"
      isRequired
      label={labels.quantity}
      max={systemConstants.MAX_INPUT_INT}
      min={1}
      onChange={(e) => {
        quantityField.input.onChange(e.target.value);
        dispatch(storeStep3({ quantity: e.target.value }));
      }}
      step="1"
    />
  );
  const renderDosage = (
    <Input
      {...dosageField.input}
      crossOrigin
      id="drugsDosage"
      isDisabled
      label={labels.dosage}
      value="Vlastné"
    />
  );
  const renderDescription = (
    <Textarea
      {...dosageDescriptionField.input}
      error={
        dosageDescriptionField.meta.touched &&
        dosageDescriptionField.meta.error && (
          <SafeHtml html={dosageDescriptionField.meta.error} wrapper="span" />
        )
      }
      help={
        <RemainingChars
          alignWithLabel
          formHelper=" "
          maxLength={200}
          valueLength={dosageDescriptionField.input.value?.length}
        />
      }
      label={labels.dosageDescription}
      maxLength={200}
      onBlur={(e) => {
        dosageDescriptionField.input.onBlur();
        if (storedValues.dosageDescription !== e.target.value)
          dispatch(storeStep3({ dosageDescription: e.target.value }));
      }}
    />
  );
  return (
    <div className={classes.drugsWrapper}>
      <div className={classes.twoFieldsRow}>
        {renderQuantity}
        {renderDosage}
      </div>
      {renderDescription}
    </div>
  );
};

export default QuantityDosage;
