import { ButtonSecondary, Modal } from '@dovera/design-system';
import { ModalType } from '../types/modal.types';
import strings from '../constants/strings';
import { useModalContext } from '../hooks/useModalContext';
import SafeHtml from '../components/SafeHtml/SafeHtml';
import { useEffect } from 'react';
import { scrollToModalTop } from '../utils/app.utils';

const texts = strings.proposals.drugs.new.modals.recommended;

interface Props {
  items: { label: string; value: string }[];
  modalID: ModalType;
  onSelect: (code: string, label: string) => void;
  type: 'products' | 'indicatorLimits';
}

const RecommendedModal = ({
  items,
  modalID,
  onSelect,
  type,
  ...other
}: Props) => {
  const modalContext = useModalContext();
  const isOpen = modalContext.isOpenModal(modalID);
  const productItem = (code: string, name: string, key: number) => (
    <div key={`recommended--${key}`}>
      <span className="fs-14 text-color-grey-light">
        {type === 'products' ? code : 'Kód indikačného obmedzenia'}
      </span>
      {type === 'indicatorLimits' && (
        <h5 className="text-color-black">{code}</h5>
      )}
      <SafeHtml
        className="text-color-black mb-small"
        html={name}
        wrapper={type === 'products' ? 'h5' : 'div'}
      />
      <ButtonSecondary onClick={() => onSelect(code, name)}>
        {texts[type]?.btn}
      </ButtonSecondary>
    </div>
  );
  useEffect(() => {
    scrollToModalTop();
  }, [isOpen]);
  return (
    <Modal
      closeOnOverlayClick={false}
      data-modal-initial-focus
      header={texts[type]?.title}
      id="drugs-proposal--recommended-products-modal"
      isVisible={isOpen}
      onHide={() => {
        modalContext.closeModal(modalID);
      }}
      shouldUsePortal={false}
      {...other}
    >
      {items?.map((i, key) => productItem(i.value, i.label, key))}
    </Modal>
  );
};

export default RecommendedModal;
