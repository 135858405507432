import strings from '../../../../../constants/strings';
import { ProposalRadioType } from '../../../../../types/proposals.types';

const texts = strings.proposals.spa.new.options;

interface ElementAttrsPayload {
  choosedValue?: 'Y' | 'N' | string;
  idProp: string;
  index: number;
  type: ProposalRadioType;
}

interface ElementAttrsResp {
  id: string;
  isChecked: boolean | undefined;
  label: string;
  value: string;
}

export const getElementAttrs = ({
  choosedValue,
  idProp,
  index,
  type,
}: ElementAttrsPayload): ElementAttrsResp => {
  let id = `${idProp}--`;
  let isChecked;
  let label = '';
  let value = '';

  switch (type) {
    case 'Má/Nemá':
      id += index === 0 ? 'has' : 'hasnt';
      isChecked = index === 0 ? choosedValue === 'Y' : choosedValue === 'N';
      label = index === 0 ? texts.has : texts.hasnt;
      value = index === 0 ? 'Y' : 'N';
      break;
    case 'Áno/Nie':
      id += index === 0 ? 'yes' : 'no';
      isChecked = index === 0 ? choosedValue === 'Y' : choosedValue === 'N';
      label = index === 0 ? texts.yes : texts.no;
      value = index === 0 ? 'Y' : 'N';
      break;
    case 'Nemá/Má':
      id += index === 0 ? 'hasnt' : 'has';
      isChecked = index === 0 ? choosedValue === 'N' : choosedValue === 'Y';
      label = index === 0 ? texts.hasnt : texts.has;
      value = index === 0 ? 'N' : 'Y';
      break;
    default:
      break;
  }
  return {
    id,
    isChecked,
    label,
    value,
  };
};
