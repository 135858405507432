import { ButtonLink, Icon, Loader } from '@dovera/design-system';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import strings from '../../../../constants/strings';
import { useAppDispatch } from '../../../../hooks/useStore';
import { RootState } from '../../../../rootReducer';
import { fetchObsahDokumentu } from '../../../../slices/dokumenty.slice';
import { GetProposalDetailDocumentsResponse } from '../../../../types/spaProposals.types';
import { cx } from '../../../../utils/exports';
import useStyles from '../../Proposals.styles';
import Section from './Section';

const texts = strings.proposals.spa.detail;

interface Props {
  data: GetProposalDetailDocumentsResponse;
  proposalId: number;
}

const Documents = ({ data, proposalId }: Props) => {
  const classes = useStyles({ proposalType: 'KN' });
  const dispatch = useAppDispatch();
  const fetchedDocuments = useSelector(
    (state: RootState) => state.dokumenty.dokumenty,
  );

  const getDocument = useCallback(
    (document: string, ciarovyKod: boolean) => {
      if (document)
        dispatch(
          fetchObsahDokumentu(
            ciarovyKod
              ? { dms: { ciarovyKod: document } }
              : { navrh: { idNavrhNaZS: proposalId, overovaciKod: document } },
            true,
            false,
          ),
        );
    },
    [dispatch, proposalId],
  );

  return (
    <Section
      extra={
        <>
          {data.dokumenty.map((d, key) => {
            const documentCode: string = d.ciarovyKod || d.overovaciKod || '';
            if (documentCode) {
              return (
                <ButtonLink
                  key={`proposal-document-${key}`}
                  className={cx(
                    classes.documentLink,
                    'no-mrg no-pad text-left',
                  )}
                  isDisabled={!!fetchedDocuments[documentCode]?.isLoading}
                  onClick={() => getDocument(documentCode, !!d.ciarovyKod)}
                >
                  {fetchedDocuments[documentCode]?.isLoading ? (
                    <Loader className="text-space-right" size={16} />
                  ) : (
                    <Icon name="file-blank" />
                  )}
                  {d.nazov}
                </ButtonLink>
              );
            }
            return <span />;
          })}
        </>
      }
      title={texts.subtitles.documents}
    />
  );
};

export default Documents;
