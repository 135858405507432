import { useCallback, useEffect } from 'react';
import { Button, ButtonLayout, Notification } from '@dovera/design-system';
import { useField, useForm } from 'react-final-form-hooks';
import { validationMsg } from '../../../../utils/form.utils';
import { VALIDATION } from '../../../../types/validation.types';
import regex from '../../../../constants/regex';
import strings from '../../../../constants/strings';
import { useSelector } from 'react-redux';
import {
  changeActiveStep,
  changeStepStatus,
  resetFormData,
  resetStep,
  storeStep2,
} from '../../../../slices/spaProposals.slice';
import { RootState } from '../../../../rootReducer';
import { checkProposalData } from '../../../../api/spaProposals';
import { useAppDispatch } from '../../../../hooks/useStore';
import { Form, Summary } from '../common/PatientStep';
import { StepWrapper } from '../common';
import { PROPOSAL_TYPES } from '../../../../types/proposals.types';
import SafeHtml from '../../../../components/SafeHtml/SafeHtml';

const texts = strings.proposals.spa.new;

const validateForm = (values: { [x: string]: any }) => {
  const errors: any = {};
  if (!values.insureeId)
    errors.insureeId = validationMsg(VALIDATION.RequiredEmpty);
  if (values.insureeId.length < 9 || values.insureeId.length > 10)
    errors.insureeId = validationMsg(VALIDATION.InsureeIdWrongLength);
  if (
    !errors.insureeId &&
    (!regex.RODNE_CISLO.test(values.insureeId) ||
      !regex.NUMBERS_ONLY.test(values.insureeId))
  )
    errors.insureeId = validationMsg(VALIDATION.InsureeIdWrongFormat);

  return errors;
};

interface Props {
  onCancel: () => void;
  onContinue: () => void;
}

const Step2 = ({ onCancel, onContinue }: Props) => {
  const dispatch = useAppDispatch();
  const { ambulance, doctor } = useSelector(
    (state: RootState) => state.spaProposals.new.stepper.step1,
  );
  const { insureeId, poiData, stepStatus } = useSelector(
    (state: RootState) => state.spaProposals.new.stepper.step2,
  );
  const currentControls = useSelector(
    (state: RootState) => state.spaProposals.new.data.controls.data,
  );
  const { errorInsuree, isLoadingControl } = useSelector(
    (state: RootState) => state.spaProposals.new.data.controls,
  );
  const {
    isReseted,
    stepper: { activeStep },
  } = useSelector((state: RootState) => state.spaProposals.new);
  const insureeLoading = isLoadingControl?.some((c) => c === 'PoistnyVztah');
  const isInactiveInsuree: boolean = currentControls.some(
    (c) => c.checkType === 'PoistnyVztah' && c.result === 'NOK',
  );
  const { form, handleSubmit, values } = useForm({
    onSubmit: () => {
      if (!errorInsuree) onContinueCallback();
    },
    initialValues: {
      insureeId: '',
    },
    validate: (valuesToValid) => validateForm(valuesToValid),
  });
  const insureeIdField = useField('insureeId', form);
  const onContinueCallback = useCallback(() => {
    if (insureeId) {
      dispatch(
        changeStepStatus({
          status: 'completed',
          step: 2,
        }),
      );
      onContinue();
    }
  }, [dispatch, insureeId, onContinue]);
  const resetStepCallback = useCallback(() => {
    dispatch(resetStep({ step: 2 }));
    dispatch(resetFormData());
  }, [dispatch]);
  const checkDataCallback = useCallback(
    (value: string) => {
      if (poiData) resetStepCallback();
      dispatch(storeStep2({ insureeId: value }));
      dispatch(
        checkProposalData({
          kontrolaTypy: ['PoistnyVztah'],
          rodneCislo: value,
        }),
      );
    },
    [dispatch, poiData, resetStepCallback],
  );
  useEffect(() => {
    if (ambulance.id && doctor.id) form.reset();
    // eslint-disable-next-line
  }, [ambulance.id, doctor.id]);
  useEffect(() => {
    if (isReseted) form.restart();
  }, [form, isReseted]);
  const renderButtons = (
    <ButtonLayout direction="horizontal">
      {isInactiveInsuree ? (
        <Button className="no-mrg" onClick={() => onCancel()}>
          {texts.buttons.cancel}
        </Button>
      ) : (
        <Button
          className="no-mrg"
          isDisabled={
            insureeLoading ||
            (values.insureeId.length >= 9 && !insureeId && !errorInsuree)
          }
          onClick={() => handleSubmit()}
        >
          {texts.buttons.continue}
        </Button>
      )}
    </ButtonLayout>
  );
  const renderContent = (
    <form id="proposal--insureeId" onSubmit={handleSubmit}>
      <Form
        errorElement={
          <Notification
            message={
              <SafeHtml html={texts.notifications.errors.inactiveInsuree} />
            }
            variant="error"
          />
        }
        errorInsuree={errorInsuree}
        insureeIdField={insureeIdField}
        insureeLoading={insureeLoading}
        isInactiveInsuree={isInactiveInsuree}
        onChange={checkDataCallback}
        onReset={resetStepCallback}
        poiData={poiData}
      />
      {renderButtons}
    </form>
  );
  return (
    <StepWrapper
      activeStep={activeStep}
      changeActiveStep={changeActiveStep}
      // eslint-disable-next-line
      content={renderContent}
      step={2}
      stepStatus={stepStatus}
      summary={<Summary insureeId={insureeId} poiData={poiData} />}
      title={texts.stepTitles.step2}
      type={PROPOSAL_TYPES.SPA}
    />
  );
};

export default Step2;
