import {
  GetFormDataResponse,
  ProposalPOIData,
} from '../../../../../../types/proposals.types';
import {
  MobilityType,
  SPAType,
} from '../../../../../../types/spaProposals.types';
import {
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
} from '@dovera/design-system';
import strings from '../../../../../../constants/strings';
import useStyles from '../../../../Proposals.styles';
import { getRadioResult } from '../../../../../../utils/spaProposals.utils';
import { calculateAge } from '../../../../../../utils/date.utils';
import { SPA_PROPOSAL_ESCORT_TYPE_AGE } from '../../../../../../constants/proposals';

interface Props {
  escortField: any;
  escortHelpingField: any;
  formData: GetFormDataResponse | null;
  insureeData: ProposalPOIData | null;
  mobilityField: any;
  spaTypeField: any;
}

const texts = strings.proposals.spa.new;

const Form = ({
  escortField,
  escortHelpingField,
  formData,
  insureeData,
  mobilityField,
  spaTypeField,
}: Props) => {
  const classes = useStyles({ proposalType: 'KN' });
  const insureeAge: number = calculateAge(insureeData?.dateOfBirth || '');
  const renderSPAType = () => {
    const spaTypes: SPAType[] = ['U', 'A'];
    if (formData?.udajDetIndSkup?.kodMieraUhradyZS === 'S')
      return (
        <>
          <div className="mb-xxsmall" />
          <div className="mb">
            <b>{`${texts.labels.bathTypeSingle}: `}</b>
            {texts.options.spaType(true).A}
          </div>
        </>
      );
    return (
      <RadioGroup
        {...spaTypeField.input}
        error={spaTypeField.meta.touched && spaTypeField.meta.error}
        id="proposal--step5--spaType"
        isRequired
        label={texts.labels.bathType}
      >
        <div className={classes.radiocheckWrapper}>
          {spaTypes.map((s) => (
            <Radio
              id={`spa-type--${s}`}
              isChecked={s === spaTypeField.input.value}
              name="spa-type"
              value={s}
            >
              {texts.options.spaType()[`${s}`]}
            </Radio>
          ))}
        </div>
        <div />
      </RadioGroup>
    );
  };
  const renderEscort = () => {
    const escortType: ('Y' | 'N')[] = ['Y', 'N'];
    return (
      <div>
        <RadioGroup
          {...escortField.input}
          error={escortField.meta.touched && escortField.meta.error}
          id="proposal--step5--escort"
          isRequired
          label={texts.labels.escort}
        >
          <div className={classes.radiocheckWrapper}>
            {escortType.map((e) => (
              <Radio
                id={`spa-escort--${e}`}
                isChecked={e === escortField.input.value}
                name="spa-escort"
                value={e}
              >
                {getRadioResult(e)}
              </Radio>
            ))}
          </div>
          <div />
        </RadioGroup>
      </div>
    );
  };
  const renderEscortHelpingWith = () => {
    const checkedValues: string[] = escortHelpingField.input.value
      ? escortHelpingField.input.value.split(';')
      : [];
    if (
      formData &&
      escortField.input.value === 'Y' &&
      insureeAge > SPA_PROPOSAL_ESCORT_TYPE_AGE
    )
      return (
        <CheckboxGroup
          {...escortHelpingField.input}
          error={
            escortHelpingField.meta.touched && escortHelpingField.meta.error
          }
          id="proposal--step5--mobility"
          isRequired
          label={texts.labels.helpingEscort}
          onChange={() => {}}
        >
          <div className={classes.radiocheckWrapper}>
            {formData.dovodySprievodu.map((d) => (
              <Checkbox
                data-checked={checkedValues.some((v) => v === d.popis)}
                id={`spa-escort--checkbox--${d.kod}`}
                onChange={() =>
                  escortHelpingField.input.onChange(
                    checkedValues.includes(d.popis)
                      ? checkedValues.filter((v) => v !== d.popis).join(';')
                      : [...checkedValues, d.popis].join(';'),
                  )
                }
                value={d.popis}
              >
                {d.popis}
              </Checkbox>
            ))}
          </div>
          <div />
        </CheckboxGroup>
      );
    return <span />;
  };
  const renderMobility = () => {
    const mobilityTypes: MobilityType[] = ['C', 'B', 'V'];
    return (
      <RadioGroup
        {...mobilityField.input}
        error={mobilityField.meta.touched && mobilityField.meta.error}
        id="mobility-type"
        isRequired
        label={texts.labels.mobility}
      >
        <div className={classes.radiocheckWrapper}>
          {mobilityTypes.map((m) => (
            <Radio
              id={`mobility-type--${m}`}
              isChecked={m === mobilityField.input.value}
              name="mobility-type"
              value={m}
            >
              {texts.options.mobilityType[`${m}`]}
            </Radio>
          ))}
        </div>
        <div />
      </RadioGroup>
    );
  };
  return (
    <>
      {renderSPAType()}
      {renderEscort()}
      {renderEscortHelpingWith()}
      {renderMobility()}
    </>
  );
};

export default Form;
