export default {
  menu: {
    home: 'Domov',
    zuc: 'Zúčtovanie',
    cl: 'Čakacie listiny',
    pcp: 'Prehľad cenových podmienok',
    zml: 'Zmluvná dokumentácia',
    parameters: 'Hodnotiace parametre',
    kn: 'Návrhy',
    pac: 'Pacienti',
    diag: 'Prehľad diagnóz',
    prof: 'Profil',
    msg: 'Správy',
  },
  submenu: {
    sendBatches: 'Odoslanie dávok',
    batchPreview: 'Prehľad dávok',
    invoices: 'Faktúry',
    disp: 'Prehľad dispenz. záznamov',
    contracts: 'Zmluvy a dodatky',
    parametersPreview: 'Prehľad parametrov',
    parametersPreviewOld: 'Prehľad parametrov old',
    paraletersSelection: 'Výber parametrov',
    newProposal: 'Nový návrh',
    proposalList: 'Zoznam podaných návrhov',
    approvedPorposalList: 'Zoznam schválených návrhov',
    productsOnStock: 'Produkty na sklade',
    moveRequest: 'Žiadosť o presun',
    ordersPreview: 'Prehľad objednávok',
    kap: 'Kapitovaní a sporní',
    zsPreview: 'Prehľad zdravotnej starostlivosti',
    zs: 'Čerpanie zdravotnej staroslivosti',
    farm: 'Farmakoterapia Vašich pacientov',
    chr: 'Chronickí pacienti vo Vašom kmeni',
  },
};
