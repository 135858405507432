// @ts-nocheck
import {
  Button,
  ButtonLayout,
  ButtonLink,
  ButtonSecondary,
  Icon,
  Modal,
} from '@dovera/design-system';
import useStyles from '../Proposals.styles';
import Illustration from '../../../assets/illustrations/missing.png';
import strings from '../../../constants/strings';
import SafeHtml from '../../../components/SafeHtml/SafeHtml';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { getFilteredControls } from '../../../utils/spaProposals.utils';
import { scrollPositionAfterModalVisible } from '../../../utils/app.utils';
// @ts-check

interface Props {
  isVisible: boolean;
  onCancel: () => void;
  onEdit: () => void;
  onHide: () => void;
  onSubmit: () => void;
}

const texts = strings.proposals.spa.modals.notValid;

const NotValidConditionsModal = ({
  isVisible,
  onCancel,
  onEdit,
  onHide,
  onSubmit,
}: Props) => {
  const classes = useStyles({ proposalType: 'KN' });
  const data = useSelector(
    (state: RootState) => state.spaProposals.new.data.controls.data,
  );
  const { formData } = useSelector(
    (state: RootState) => state.spaProposals.new.data,
  );
  const { spaConfirmation, spaContraindications, spaExaminations, spaSVLZ } =
    useSelector((state: RootState) => state.spaProposals.new.stepper.step3);
  const { isLoading } = useSelector(
    (state: RootState) => state.spaProposals.new.save,
  );
  const renderContent = (
    <ul className="text-left">
      {spaConfirmation === 'N' && (
        <SafeHtml
          className="mb-xxsmall"
          html={texts.conditions.recommendations70}
          wrapper="li"
        />
      )}
      {spaContraindications === 'Y' && (
        <SafeHtml
          className="mb-xxsmall"
          html={texts.conditions.contraindications}
          wrapper="li"
        />
      )}
      {spaExaminations === 'N' && (
        <SafeHtml
          className="mb-xxsmall"
          html={texts.conditions.noExaminations}
          wrapper="li"
        />
      )}
      {spaSVLZ === 'N' && (
        <SafeHtml
          className="mb-xxsmall"
          html={texts.conditions.svlz}
          wrapper="li"
        />
      )}
      {getFilteredControls(data).map((s, key) => (
        <SafeHtml
          key={`txs-spa-control--${key}`}
          className="mb-xxsmall"
          html={
            s === 'CerpanieKNsPeriod'
              ? texts.conditions.txsControls.CerpanieKNsPeriod(
                  Number(formData.data?.udajParUdalIndSkup?.perioda),
                )
              : texts.conditions.txsControls[`${s}`]
          }
          wrapper="li"
        />
      ))}
    </ul>
  );
  const renderButtons = (
    <ButtonLayout direction="horizontal">
      <div>
        <Button
          className="no-mrg-bottom text-space-right"
          isDisabled={isLoading}
          isLoading={isLoading}
          onClick={onSubmit}
        >
          {texts.buttons.submit}
        </Button>
        <ButtonSecondary
          className="no-mrg-bottom text-space-left"
          isDisabled={isLoading}
          onClick={onCancel}
        >
          {texts.buttons.cancel}
        </ButtonSecondary>
      </div>
      <ButtonLink
        className="no-mrg-bottom"
        isDisabled={isLoading}
        onClick={onEdit}
      >
        <Icon name="16-edit" size="medium" />
        {texts.buttons.edit}
      </ButtonLink>
    </ButtonLayout>
  );
  return (
    <Modal
      className={classes.modalNotValidConditions}
      closeOnOverlayClick={false}
      data-modal-initial-focus
      footer={renderButtons}
      header=""
      id="proposal-not-valid-conditions--modal"
      isVisible={isVisible}
      onHide={() => {
        onHide();
        scrollPositionAfterModalVisible();
      }}
    >
      <img alt="Ilustrácia" src={Illustration} />
      <SafeHtml className="mb-small" html={texts.title} wrapper="h4" />
      <SafeHtml className="mb" html={texts.description} wrapper="div" />
      {renderContent}
    </Modal>
  );
};

export default NotValidConditionsModal;
