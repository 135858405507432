// @ts-nocheck
import { useEffect, useState } from 'react';
import StaticMenuLayout from '../../../layouts/StaticMenuLayout';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import strings from '../../../constants/strings';
import SafeHtml from '../../../components/SafeHtml/SafeHtml';
import useStyles from '../Proposals.styles';
import { cx } from '../../../utils/exports';
import {
  Button,
  ButtonLayout,
  ButtonSecondary,
  Icon,
} from '@dovera/design-system';
import IllustrationSuccess from '../../../assets/illustrations/success-mamog.png';
import IllustrationWarning from '../../../assets/illustrations/error-kid.png';
import IllustrationError from '../../../assets/illustrations/error-mamog.png';
import { reset } from '../../../slices/spaProposals.slice';
import { useAppDispatch } from '../../../hooks/useStore';
import { useNavigate } from 'react-router';
import { getFilteredControls } from '../../../utils/spaProposals.utils';
// @ts-check

const texts = strings.proposals.spa.new.result;

interface Props {
  proposalNumber?: string | null;
}

const SaveProposalResult = ({ proposalNumber }: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles({ proposalType: 'KN' });
  const navigate = useNavigate();
  const [result, setResult] = useState<{
    description: string;
    primaryBtn: string;
    showSecondaryBtn: boolean;
    status: 'success' | 'warning' | 'error' | null;
    title: string;
  }>({
    description: texts.success.description,
    primaryBtn: texts.success.btn,
    showSecondaryBtn: true,
    status: 'success',
    title: texts.success.title(proposalNumber),
  });
  const {
    data: {
      controls: { data },
    },
    save,
    stepper: { step2, step3 },
  } = useSelector((state: RootState) => state.spaProposals.new);
  useEffect(() => {
    if (save.error) {
      setResult({
        description: texts.error.description,
        primaryBtn: texts.error.btn,
        showSecondaryBtn: false,
        status: 'error',
        title: texts.error.title(proposalNumber),
      });
    } else if (
      !!getFilteredControls(data).length ||
      step2.stepStatus === 'error' ||
      step3.stepStatus === 'error'
    ) {
      setResult({
        description: texts.warning.description,
        primaryBtn: texts.warning.btn,
        status: 'warning',
        showSecondaryBtn: true,
        title: texts.warning.title(proposalNumber),
      });
    } else {
      setResult({
        description: texts.success.description,
        primaryBtn: texts.success.btn,
        showSecondaryBtn: true,
        status: 'success',
        title: texts.success.title(proposalNumber),
      });
    }
  }, [data, proposalNumber, save, step2.stepStatus, step3.stepStatus]);
  const renderButtons = (
    <ButtonLayout direction="horizontal">
      {result.showSecondaryBtn && (
        <ButtonSecondary onClick={() => dispatch(reset())}>
          <Icon className="text-space-half-right" name="plus" size="medium" />
          {texts[`${result.status}`]?.secondaryBtn}
        </ButtonSecondary>
      )}
      <Button
        className={cx(result.showSecondaryBtn && 'text-space-left')}
        onClick={() => {
          if (result.status === 'error') {
            dispatch(reset());
          } else {
            navigate('/');
          }
        }}
      >
        {result.primaryBtn}
      </Button>
    </ButtonLayout>
  );
  if (!result.status) return <span />;
  return (
    <StaticMenuLayout
      backgroundWhite
      checkSystems={{ systems: ['MD', 'TXS'] }}
      withoutPadding
    >
      <div className={classes.result}>
        <img
          alt="Ilustrácia"
          src={
            result.status === 'error'
              ? IllustrationError
              : result.status === 'warning'
                ? IllustrationWarning
                : IllustrationSuccess
          }
        />
        <SafeHtml
          className={cx(result.status, 'mb-small')}
          html={result.title}
          wrapper="h4"
        />
        <SafeHtml className="mb-large" html={result.description} />
        {renderButtons}
      </div>
    </StaticMenuLayout>
  );
};

export default SaveProposalResult;
