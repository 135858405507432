import { Notification } from '@dovera/design-system';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../rootReducer';
import { CheckType, Result } from '../../../../../types/proposals.types';
import SafeHtml from '../../../../../components/SafeHtml/SafeHtml';
import {
  getMessageAssignedToCheckType,
  isCheckWithSameMessage,
} from '../../../../../utils/drugProposals.utils';
import { checkWithNoMessage } from '../../../../../constants/drugProposals';

interface CheckNotificationsProps {
  checkTypes: CheckType[];
}

const CheckNotifications = ({ checkTypes }: CheckNotificationsProps) => {
  const { controls } = useSelector(
    (state: RootState) => state.drugProposals.new.data,
  );

  const shouldDisplay = (checkType: CheckType): boolean => {
    const debtorArr: CheckType[] = ['Dlznik', 'DlznikVociCSParNeodklZS'];
    if (
      (checkType === 'Dlznik' || checkType === 'DlznikVociCSParNeodklZS') &&
      !debtorArr.every((el) =>
        controls?.data
          ?.filter((c) => c.result === 'NOK')
          ?.map((c) => c.checkType)
          ?.includes(el),
      )
    )
      return false;
    if (
      isCheckWithSameMessage(controls?.data) &&
      checkType === 'DlznikVociCSParNeodklZS'
    )
      return false;
    if (!checkTypes.includes(checkType)) return false;
    if (checkWithNoMessage.includes(checkType)) return false;

    return true;
  };

  return controls?.data
    .filter((i) => i.result === Result.NOK && shouldDisplay(i.checkType))
    .map((item) => (
      <Notification
        message={
          <SafeHtml html={getMessageAssignedToCheckType(item.checkType)} />
        }
        variant="error"
      />
    ));
};

export default CheckNotifications;
