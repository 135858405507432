import { createUseStyles } from 'react-jss';
import { color } from '../../constants';
import { breakpoints, colors, getRem } from '@dovera/design-system';

export default createUseStyles({
  attachedFile: {
    '& svg': {
      verticalAlign: 'middle',
      marginTop: getRem(-3),
      marginRight: getRem(8),
    },
    '& button': {
      background: 'transparent',
      border: 'none',
      outline: 'none',
      '& svg': {
        marginLeft: getRem(8),
        cursor: 'pointer',
        width: getRem(16),
        height: getRem(16),
      },
    },
  },
  fileInput: {
    display: 'none',
  },
  fileNameContainer: {
    display: 'flex',
    justifyContent: 'row',
    alignItems: 'center',
    '& :first-child': {
      marginRight: 12,
    },
  },
  uploadZone: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    maxWidth: getRem(620),
    border: `1px dashed ${color('grey', 200)}`,
    backgroundColor: color('grey', 50),
    boxSizing: 'border-box',
    borderRadius: 8,
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: getRem(20),
    cursor: 'pointer',
    '& button': {
      minHeight: 0,
      '& svg': {
        width: getRem(24),
        height: getRem(24),
        verticalAlign: 'middle',
        marginTop: getRem(-3),
        marginRight: getRem(8),
      },
    },
    '&:hover': {
      backgroundColor: color('success', 100),
    },
    '&.file-uploader--error': {
      border: `1px dashed ${color('error', 500)}`,
      backgroundColor: color('white'),
    },
    [`@media screen and (max-width: ${breakpoints.s}px)`]: {
      flexDirection: 'column',
    },
  },
  uploadZoneActive: {
    backgroundColor: color('success', 100),
  },
  uploadZoneError: {
    border: `1px dashed ${colors.error[600]}`,
    backgroundColor: colors.error[100],
    color: colors.error[600],
    position: 'relative',
    marginLeft: getRem(16),
    '&:before': {
      content: '""',
      width: 2,
      height: `calc(100% + ${getRem(40)})`,
      backgroundColor: colors.error[600],
      position: 'absolute',
      left: getRem(-16),
      top: getRem(-40),
    },
  },
  labelError: {
    color: colors.error[600],
    marginBottom: getRem(12),
    marginLeft: getRem(16),
  },
  uploadLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      margin: `0 ${getRem(8)}`,
    },
    '& span, & button, & a': {
      margin: `0 ${getRem(6)}`,
    },
  },
});
