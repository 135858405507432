import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import cx from 'classnames';

import { normalizeCase } from '../utils';
import useStyles from './styles';
import { Choice } from 'choices.js';

type SuggestionItemProps = {
  highlight?: string;
  isDisabled?: boolean;
  isFocused?: boolean;
  noMatchHint?: { label: ReactNode; value?: string };
  /* eslint-disable */
  onSelect?: (option: Choice) => void;
  other?: any;
  option: Choice;
  template?: (option: any) => ReactNode;
  /* eslint-enable */
} & React.JSX.IntrinsicElements['button'];

const SuggestionItem = ({
  highlight,
  isDisabled,
  isFocused,
  noMatchHint,
  onSelect,
  option,
  other,
  template,
}: SuggestionItemProps) => {
  const classes = useStyles();
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (buttonRef.current !== null && isFocused) {
      buttonRef.current.focus();
    }
  }, [isFocused, option]);

  const highlightedTitle = useMemo(() => {
    const v = normalizeCase(highlight || '');
    const t = normalizeCase(option.label);

    const start = t.indexOf(v);
    const end = start + v.length;

    const prefix = option.label.substring(0, start);
    const match = option.label.substring(start, end);
    const suffix = option.label.substring(end);

    if (template) {
      return template(option);
    }
    return [
      <span key="prefix">{prefix}</span>,
      <mark key="match">{match}</mark>,
      <span key="suffix">{suffix}</span>,
    ];
  }, [highlight, option, template]);

  return (
    <button
      ref={buttonRef}
      aria-selected="false"
      className={cx([
        `dropdown__item`,
        classes.item,
        {
          [classes.isDisabled]: isDisabled,
        },
      ])}
      disabled={isDisabled}
      onClick={() => {
        if (onSelect)
          onSelect(
            noMatchHint && noMatchHint.value
              ? { label: noMatchHint.value, value: noMatchHint.value }
              : option,
          );
      }}
      role="option"
      tabIndex={-1}
      type="button"
      // eslint-disable-next-line
      {...other}
    >
      {noMatchHint ? (
        noMatchHint.label
      ) : (
        <span>
          {highlightedTitle}
          {option?.customProperties?.hint ? (
            <span className={classes.hint}>
              , {option?.customProperties?.hint}
            </span>
          ) : null}
        </span>
      )}
    </button>
  );
};

export default SuggestionItem;
