import { Choice } from 'choices.js';
import removeAccents from 'remove-accents';

export const normalizeIndex = (index: number, length: number) =>
  (length + index) % length;

const doesMatch = (
  option: Choice,
  value: string,
  fromStart: boolean = true,
) => {
  const normalizedTitle = normalizeString(option.label);
  const normalizedValue = normalizeString(value);
  return fromStart
    ? normalizedTitle.startsWith(normalizedValue)
    : normalizedTitle.includes(normalizedValue);
};

const normalizeHints = (options: Choice[]): Choice[] => {
  const duplicates: string[] = [];
  const titles = options.map((o) => o.label);

  titles.forEach((t, i) => {
    if (!duplicates.includes(t) && titles.lastIndexOf(t) !== i) {
      duplicates.push(t);
    }
  });

  return options.map((o) => ({
    ...o,
    hint:
      duplicates.indexOf(o.label) !== -1
        ? o?.customProperties?.hint
        : undefined,
  }));
};

export const normalizeAccents = (s: string) => removeAccents(s);
export const normalizeUnicode = (s: string) =>
  s
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .toLocaleLowerCase();
export const normalizeCase = (s: string) => s.toLocaleLowerCase();
export const normalizeString = (s: string) =>
  normalizeUnicode(normalizeCase(normalizeAccents(s)));

export const optionComparator = (value: string) => {
  const vv = normalizeCase(value);
  const vvv = normalizeAccents(value);

  return (a: Choice, b: Choice) => {
    const aa = normalizeCase(a.label);
    const bb = normalizeCase(b.label);
    const aaa = normalizeAccents(aa);
    const bbb = normalizeAccents(bb);

    // case-normalized score
    const ss = aa.indexOf(vv) - bb.indexOf(vv);

    // case-and-accent normalized score
    const sss = aaa.indexOf(vvv) - bbb.indexOf(vvv);

    // alphabetical score
    const abc = aaa < bbb ? -1 : 1;

    // length score
    const l = a.label.length < b.label.length ? -1 : 1;

    // weighted score
    return 4 * ss + 3 * sss + 2 * abc + l;
  };
};

export const filterOptions = (
  options: Choice[],
  value: string,
  fromStart?: boolean,
) =>
  normalizeHints(
    options.filter((option) => doesMatch(option, value, fromStart)).concat(), // clone
  );

/**
 * Effect for dynamically resize textarea field
 * @date 2. 5. 2023 - 14:18:45
 *
 * @param {string} id
 * @param {string} value
 */
export const setTextareaHeight = (id: string, value: string): boolean => {
  const textarea = getElement(id);
  if (!textarea) return false;

  const computedStyle = window.getComputedStyle(textarea);
  const fontSize = parseFloat(computedStyle.fontSize);
  const {
    fontFamily,
    lineHeight,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
  } = computedStyle;

  const textareaWidth =
    textarea.clientWidth - parseFloat(paddingLeft) - parseFloat(paddingRight);

  const valueHeight = getValueHeight(
    value,
    fontSize,
    fontFamily,
    parseFloat(lineHeight),
    textareaWidth,
  );

  const textareaHeight =
    valueHeight + parseFloat(paddingTop) + parseFloat(paddingBottom);

  textarea.style.height = `${textareaHeight}px`;

  return true;
};

/**
 * Temporary DOM element for get text width
 *
 * @param {string} value
 * @param {number} fontSize
 * @param {string} fontFamily
 * @returns {number}
 */
const getValueHeight = (
  value: string,
  fontSize: number,
  fontFamily: string,
  lineHeight: number,
  width: number,
): number => {
  const element = document.createElement('span');
  element.style.visibility = 'hidden';
  element.style.position = 'absolute';
  element.style.width = `${width}px`;
  element.style.lineHeight = `${lineHeight}px`;
  element.textContent = value;

  element.style.fontSize = `${fontSize}px`;
  element.style.fontFamily = fontFamily;

  document.body.appendChild(element);
  const height = element.offsetHeight;

  document.body.removeChild(element);

  return height;
};

export const getElement = (id: string): HTMLElement | null =>
  document.getElementById(id);
