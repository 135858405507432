import { Grid, GridCol, Notification, Skeleton } from '@dovera/design-system';
import strings from '../../../../constants/strings';
import {
  GetProposalDetailDocumentsResponse,
  GetProposalDetailResponse,
} from '../../../../types/spaProposals.types';
import AdditionalInfo from './AdditionalInfo';
import Doctor from './Doctor';
import Documents from './Documents';
import Information from './Information';
import Patient from './Patient';

const texts = strings.proposals.spa.detail;

interface Props {
  data: GetProposalDetailResponse;
  documents: {
    data: GetProposalDetailDocumentsResponse | null;
    error: string | null;
    isLoading: boolean;
  };
  proposalId: number;
}

const Content = ({ data, documents, proposalId }: Props) => {
  const { doplnujuceInformacie, informacie, navrhujuciLekar, pacient } = data;

  return (
    <>
      <Grid className="mb-xlarge">
        <GridCol size={6}>
          <Patient pacient={pacient} />
        </GridCol>
        <GridCol size={6}>
          <Doctor navrhujuciLekar={navrhujuciLekar} />
        </GridCol>
      </Grid>
      <Grid>
        <GridCol size={6}>
          <Information informacie={informacie} />
        </GridCol>
        <GridCol size={6}>
          <AdditionalInfo doplnujuceInformacie={doplnujuceInformacie} />
          <div className="mb-xlarge" />
          {documents.data?.dokumenty && (
            <Documents data={documents.data} proposalId={proposalId} />
          )}
          {documents.isLoading && <Loader />}
          {documents.error && <Error error={documents.error} />}
        </GridCol>
      </Grid>
    </>
  );
};

const Loader = () => (
  <>
    <h4 className="mb-small">
      <Skeleton width="50%" />
    </h4>
    <span className="d-block mb-small">
      <Skeleton width="40%" />
    </span>
  </>
);

const Error = ({ error }: { error: string }) => (
  <>
    <h4 className="mb-small">{texts.subtitles.documents}</h4>
    <Notification message={error} variant="error" />
  </>
);

export default Content;
