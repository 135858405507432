import { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Icon,
  Input,
  Loader,
  Modal,
  Notification,
  color,
} from '@dovera/design-system';
import strings from '../../../constants/strings';
import useStyles from '../Proposals.styles';
import { VALIDATION } from '../../../types/validation.types';
import { validation } from '../../../constants/validation';
import { saveReservationCode } from '../../../api/spaProposals';
import { ErrorResponse } from '../../../types';
import { navrhyRoutes } from '../../../routes';
import {
  createViewUrl,
  scrollPositionAfterModalVisible,
} from '../../../utils/app.utils';
import { useNavigate } from 'react-router';

interface Props {
  isVisible: boolean;
  onHide: () => void;
}

const texts = strings.proposals.spa.modals.reservationCode;

const ReservationCodeModal = ({ isVisible, onHide }: Props) => {
  const classes = useStyles({ proposalType: 'KN' });
  const navigate = useNavigate();
  const [reservationCode, setReservationCode] = useState('');
  const [inputError, setInputError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isCorrectCode, setIsCorrectCode] = useState(false);
  const onSubmit = useCallback(() => {
    if (reservationCode) {
      setIsLoading(true);
      setError('');
      setInputError('');
      saveReservationCode({ pin: reservationCode })
        .then((data) => {
          setIsCorrectCode(true);
          setTimeout(() => {
            navigate(
              createViewUrl(navrhyRoutes.zoznamSchvalenychNavrhov, {
                id: data.idNavrhNaZS,
                cisloNavrhu: data.cisloNavrhu,
                pin: reservationCode,
              }),
            );
          }, 1000);
        })
        .catch((err: { response: { data: ErrorResponse } }) => {
          if (err.response.data.kod === 'KN-022') {
            setInputError(err.response.data.text);
          } else {
            setError(err.response.data.detail);
            setInputError(' ');
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (!reservationCode) {
      setInputError(validation[VALIDATION.ProposalReservationCodeNotValid]);
    }
  }, [navigate, reservationCode]);
  useEffect(() => {
    if (!isVisible && reservationCode) {
      setReservationCode('');
      setError('');
      setInputError('');
      setIsCorrectCode(false);
    }
  }, [isVisible, reservationCode]);
  const renderInput = (
    <div className={classes.inputWithState}>
      <Input
        addonsInside={isLoading}
        crossOrigin
        error={inputError}
        id="proposals--reservation-code"
        label={texts.label}
        onChange={(e) => {
          if (inputError) setInputError('');
          if (error) setError('');
          setReservationCode(e.target.value);
          setIsCorrectCode(false);
        }}
        rightAddons={isLoading && <Loader size={24} />}
        value={reservationCode}
      />
      {isCorrectCode && <Icon color={color('primary')} name="16-check" />}
    </div>
  );
  const renderButton = (
    <Button
      className="mb-xxsmall"
      isDisabled={isCorrectCode || isLoading}
      isLoading={isCorrectCode}
      onClick={onSubmit}
    >
      {texts.button}
    </Button>
  );
  const renderNotification = error && (
    <Notification message={error} variant="error" />
  );
  return (
    <Modal
      className={classes.modal}
      closeOnOverlayClick={false}
      data-modal-initial-focus
      header={texts.title}
      id="spa-proposals--reservation-modal"
      isVisible={isVisible}
      onHide={() => {
        setError('');
        setInputError('');
        setReservationCode('');
        onHide();
        scrollPositionAfterModalVisible();
      }}
    >
      {renderInput}
      {renderNotification}
      {renderButton}
    </Modal>
  );
};

export default ReservationCodeModal;
