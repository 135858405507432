/* eslint-disable */
export enum VALIDATION {
  InProgress = 'REQUEST_IN_PROGRESS',
  LoginExists = 'LOGIN_USED',
  LoginRegex = 'LOGIN_CRITERIA',
  LoginLength = 'LOGIN_LENGTH',
  LoginEmpty = 'LOGIN_CURRENT_EMPTY',
  LoginNewEmpty = 'LOGIN_NEW_EMPTY',
  LoginIncorrect = 'LOGIN_INCORRECT',
  PasswordEmpty = 'PWD_EMPTY',
  PasswordNewEmpty = 'PWD_NEW_EMPTY',
  PasswordRepeatEmpty = 'PWD_RPT_EMPTY',
  PasswordNewSameAsOld = 'PWD_OLD_NEW',
  PasswordRegex = 'PWD_CRITERIA',
  WrongPassword = 'PWD_OLD',
  PasswordRepeatNotSame = 'PWD_REPEAT',
  MaxAttempts = 'MAX_ATTEMPTS',
  MaxAttemptsLogin = 'MAX_ATTEMPTS_LOGIN',
  MaxAttemptsPassword = 'MAX_ATTEMPTS_PASSWORD',
  RequiredEmpty = 'EMPTY',
  RequiredFirstName = 'FIRST_NAME',
  RequiredLastName = 'LAST_NAME',
  RequiredName = 'NAME',
  RequiredVs = 'VS',
  LettersOnly = 'LETTERS_ONLY',
  NumbersOnly = 'NUMBERS_ONLY',
  EmailIncorrect = 'EMAIL_INCORRECT',
  RepeatEmailIncorrect = 'REPEAT_EMAIL_INCORRECT',
  PhoneIncorrect = 'PHONE_INCORRECT',
  MobileIncorrect = 'MOBILE_INCORRECT',
  InsureeIdWrongLength = 'WRONG_LENGTH_INSUREE_ID',
  InsureeIdWrongFormat = 'WRONG_FORMAT_INSUREE_ID',
  DateEmptyDefault = 'DATE_EMPTY_DEFAULT',
  DateIncorrect = 'DATE_INCORRECT',
  ProposalReservationCodeNotValid = 'PROPOSAL_RESERVATION_CODE_INVALID',
  ProposalEscortReason = 'PROPOSAL_ESCORT_REASON',
  NotDZPInsuree = 'NOT_DZP_INSUREE',
  // Autentifikacia
  AuthChooseOneOption = 'VYBERTE_JEDNU_MOZNOST',
  AuthDefaultError = 'DEFAULT_ERROR',
  AuthStatutoryError = 'DEFAULT_STAT_ERROR',
  AuthIncorrectDoctorCode = 'CHYBA_NESPRAVNY_KOD_LEKARA',
  AuthAlreadyPersonalizedAccount = 'CHYBA_PERSONALIZOVANY_UCET',
  AuthAlreadyPersonalizedDifferentAccount = 'CHYBA_PERSONALIZOVANY_INY_UCET',
  AuthDoctorStatDifferentPerson = 'CHYBA_LEKAR_A_STATUTAR_ROZDIELNE_FO',
  AuthStatsNotFound = 'CHYBA_NENAJDENI_STATUTARI',
  AuthIncorrectRoles = 'CHYBA_NEPOVOLENA_KOMBINACIA_ROLI',
  AuthIncorrectStatId = 'CHYBA_NESPRAVNE_ID_STATUTARA',
  AuthIncorrectRolesCount = 'CHYBA_NEPOVOLENY_POCET_ROLI',
  AuthStatutoryDifferentAccount = 'CHYBA_STATUTAR_VYTVORENY',
  AuthInputNotSameSTAT = 'CHYBHA_STATUTAR_NEZHODA_S_LEKAROM',
  AuthInputNotSameDoctor = 'CHYBA_LEKAR_NEZHODA_SO_STATUTAROM',
  AuthReservedAccountStat = 'INFO_UCET_REZERVOVANY_INEMU_STATUTAROVI',
  AuthErrorStatNotFound = 'CHYBA_NENAJDENI_STATUTARI',
  SqlInjection = 'SQL_INJECTION',
  ForbiddenFileType = 'ForbiddenFileType',
  // Zuctovanie
  ChybnaKombinaciaVstupnychUdajov = 'ChybnaKombinaciaVstupnychUdajov',
  ChybaPriZiskavaniDokumentuDms = 'ChybaPriZiskavaniDokumentuDms',
  ChybaPriZiskavaniDokumentuTxs = 'ChybaPriZiskavaniDokumentuTxs',
  NepodporovanyTypDavky = 'NepodporovanyTypDavky',
  ChybnaSyntax = 'ChybnaSyntax',
  OpravnaDavkakNeexistujucejDavke = 'OpravnaDavkakNeexistujucejDavke',
  DuplicitnaDavka = 'DuplicitnaDavka',
  ChybnaSemantika = 'ChybnaSemantika',
  NenajdeneDavkyPodlaIdSkupiny = 'NenajdeneDavkyPodlaIdSkupiny',
  ChybaPriZiskavaniProtokoluTxs = 'ChybaPriZiskavaniProtokoluTxs',
  ChybaPriZiskavaniFakturApm = 'ChybaPriZiskavaniFakturApm',
  DavkaNepatriPoskytovatelovi = 'DavkaNepatriPoskytovatelovi',
  NenajdeneDavky = 'NenajdeneDavky',
  NenajdeneZmluvy = 'NenajdeneZmluvy',
  ChybaPriPotvrdzovaniFakturyApm = 'ChybaPriPotvrdzovaniFakturyApm',
  MaxVelkostDavky = 'MaxVelkostDavky',
  PovoleneTypyPriloh = 'PovoleneTypyPriloh',
  MaximalnaVelkostPrilohSpolu = 'MaximalnaVelkostPrilohSpolu',
  ChybneDavky = 'ChybneDavky',
  MaximalnaSumaFaktury = 'MaximalnaSumaFaktury',
  NepovolenyVariabilnySymbol = 'NepovolenyVariabilnySymbol',
  NepripojenaFaktura = 'NepripojenaFaktura',
  NepripojenyDokumentOdovodnenia = 'NepripojenyDokumentOdovodnenia',
  ChybneRozdelenie = 'ChybneRozdelenie',
  ChybneObdobie = 'ChybneObdobie',
  ChybaPriUkladaniDokumentovFaktury = 'ChybaPriUkladaniDokumentovFaktury',
  ChybaPriParovaniFaktury = 'ChybaPriParovaniFaktury',
  NesmieBytPripojenaFaktura = 'NesmieBytPripojenaFaktura',
  NiesuVyplneneUdajeFaktury = 'NiesuVyplneneUdajeFaktury',
  NenajdenyDokumentFaktury = 'NenajdenyDokumentFaktury',
  ChybaPriParovaniDavky = 'ChybaPriParovaniDavky',
  MaximalnaVelkostPrilohy = 'MaximalnaVelkostPrilohy',
  NenajdeneDavkyCL = 'NenajdeneDavkyCL',
  ChybneIdOdoslaniaCL = 'ChybneIdOdoslaniaCL',
  ChybnyTypDavkyCL = 'ChybnyTypDavkyCL',
  ChybnaPrislusnostDavkyCL = 'ChybnaPrislusnostDavkyCL',
  ChybnaChronologiaCL = 'ChybnaChronologiaCL',
  DuplicitaDavokCL = 'DuplicitaDavokCL',
  FutureBatchCL = 'FutureBatchCL',
  NeplatneCisloUtvaruCL = 'NeplatneCisloUtvaruCL',
  NespravnyCharakterCL = 'NespravnyCharakterCL',
  NespravnyTypCL = 'NespravnyTypCL',
  NespravnyFormatIcoCL = 'NespravnyFormatIcoCL',
  NespravnyFormatDatumCL = 'NespravnyFormatDatumCL',
  NespravnyFormatPoradieCL = 'NespravnyFormatPoradieCL',
  NespravnyFormatDokladyCL = 'NespravnyFormatDokladyCL',
  NespravnyKodZPCL = 'NespravnyKodZPCL',
  NespravnyPocetPipeCL = 'NespravnyPocetPipeCL',
  NevalidnySuborCL = 'NevalidnySuborCL',
  DavkuCLNiejeMozneVytvoritPred2024 = 'DavkuCLNiejeMozneVytvoritPred2024',
  // Navrhy
  ProductEmpty = 'ProductEmpty',
  DiagnoseEmpty = 'DiagnoseEmpty',
  GreaterThanZero = 'GreaterThanZero',
  MaxIntValue = 'MaxIntValue',
  OnlyInteger = 'OnlyInteger',
}
