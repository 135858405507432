import { strings } from './strings';
import app from './app';
import dashboard from './dashboard';
import zuctovanie from './zuctovanie';
import cakacky from './cakacky';
import cobrowse from './cobrowse';
import pcp from './pcp';
import parameters from './parameters';
import proposals from './proposals';

const modules = {
  app,
  cakacky,
  cobrowse,
  dashboard,
  pcp,
  zuctovanie,
  parameters,
  proposals,
};

export default {
  ...strings,
  ...modules,
};
