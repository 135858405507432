import {
  Button,
  ButtonLayout,
  ButtonSecondary,
  Container,
  Radio,
  RadioGroup,
} from '@dovera/design-system';
import ContentHeader from '../../../components/ContentHeader/ContentHeader';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import strings from '../../../constants/strings';
import routes, { navrhyRoutes } from '../../../routes';
import { useEffect, useState } from 'react';
import StaticMenuLayout from '../../../layouts/StaticMenuLayout';
import { PROPOSAL_TYPES } from '../../../types/proposals.types';
import { useNavigate } from 'react-router';
import { cx } from '../../../utils/exports';
import useStyles from '../Proposals.styles';
import useStylesApp from '../../../App.styles';
import {
  getNewProposalContent,
  newProposalAccess,
} from '../../../utils/proposals.utils';
import { useQuery } from '../../../hooks/useQuery';
import { useAppDispatch } from '../../../hooks/useStore';
import { dajSystemoveParametre } from '../../../api/poskytovatel';
import { SystemoveParametreNazovConfigu } from '../../../types/api/poskytovatel';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { LoadingState } from '../../../types';
import SystemStatusLoader from '../../../layouts/SystemStatusLoader';
import { EmptyState } from '../../../components';
import { hasSection } from '../../../utils/poskytovatel.utils';
import { EPSections } from '../../../constants/systemConstants';

const { common, spa, types } = strings.proposals;

const NewProposal = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles({ proposalType: 'LN' });
  const classesApp = useStylesApp();
  const queryType = useQuery()?.get('typ');
  const navigate = useNavigate();
  const [proposalType, setProposalType] = useState<PROPOSAL_TYPES | string>(
    queryType || PROPOSAL_TYPES.Drugs,
  );
  const { dataState } = useSelector(
    (state: RootState) => state.app.systemoveParametre,
  );
  const isLoadedThemes: boolean = useSelector(
    (state: RootState) => !!state.poskytovatel.temy?.length,
  );
  useEffect(() => {
    dispatch(
      dajSystemoveParametre({
        nazovSekcie: SystemoveParametreNazovConfigu.navrhy,
      }),
    );
  }, [dispatch]);
  useEffect(() => {
    if (dataState === LoadingState.filled && isLoadedThemes) {
      if (
        !hasSection(EPSections.Kn, 'Navrhy') &&
        !hasSection(EPSections.Ln, 'Navrhy')
      )
        navigate(routes.dashboard);
      if (
        !hasSection(EPSections.Kn, 'Navrhy') &&
        hasSection(EPSections.Ln, 'Navrhy')
      )
        navigate(`${navrhyRoutes.novyNavrh}?typ=${proposalType}`);
      if (
        hasSection(EPSections.Kn, 'Navrhy') &&
        !hasSection(EPSections.Ln, 'Navrhy')
      )
        navigate(`${navrhyRoutes.novyNavrh}?typ=KN`);
    }
  }, [dataState, isLoadedThemes, navigate, proposalType]);
  const newProposalContent = getNewProposalContent(queryType || undefined);
  const renderHeader = (
    <ContentHeader padBottom>
      <Container>
        <Breadcrumbs
          items={[
            {
              name: common.new.breadcrumbs.proposals,
              route: '#',
            },
            {
              name: common.new.breadcrumbs.listOfProposals,
              route: navrhyRoutes.zoznamPodanychNavrhov,
            },
            {
              name: common.new.title,
            },
          ]}
        />
      </Container>
      <h2 className="no-mrg">{spa.new.title}</h2>
    </ContentHeader>
  );
  const renderChoose = (
    <RadioGroup
      id="choose-proposal-type"
      onChange={(e) => {
        const { value } = e.target as HTMLInputElement;
        setProposalType(value);
      }}
    >
      {Object.keys(PROPOSAL_TYPES)
        .reverse()
        .map((key) => (
          <Radio
            className="mb-small"
            id={`proposal--${key}`}
            isChecked={proposalType === PROPOSAL_TYPES[key]}
            name="choose-proposal-type"
            value={PROPOSAL_TYPES[key]}
          >
            {types[PROPOSAL_TYPES[key]]}
          </Radio>
        ))}
    </RadioGroup>
  );
  const renderButtons = (
    <ButtonLayout direction="horizontal">
      <Button
        className="no-mrg-bottom"
        onClick={() =>
          navigate(`${navrhyRoutes.novyNavrh}?typ=${proposalType}`)
        }
      >
        {common.buttons.continue}
      </Button>
      <ButtonSecondary
        className="no-mrg-bottom"
        onClick={() => navigate(navrhyRoutes.zoznamPodanychNavrhov)}
      >
        {common.buttons.cancel}
      </ButtonSecondary>
    </ButtonLayout>
  );
  if (dataState === LoadingState.fetching || !isLoadedThemes)
    return <SystemStatusLoader />;
  if (dataState === LoadingState.error)
    return <EmptyState reason="500" type="error" />;
  if (!newProposalAccess()) navigate(routes.dashboard);
  if (newProposalContent) return newProposalContent;
  return (
    <StaticMenuLayout
      backgroundWhite
      contentHeader={renderHeader}
      withoutPadding
    >
      <div className={cx(classesApp.pageLayout, classes.section)}>
        {renderChoose}
        {renderButtons}
      </div>
    </StaticMenuLayout>
  );
};

export default NewProposal;
