import axios, { AxiosResponse } from 'axios';
import {
  GetDiagnosesListPayload,
  GetDiagnosesListReponse,
  ProposalDiagnose,
} from '../types/proposals.types';
import { Choice } from 'choices.js';

const API_CONTROLLER = `KupelneNavrhy`;

const API_URL = `${window.env?.config?.apiPath}/${API_CONTROLLER}/`;

export const getDiagnosesList = async (
  payload: GetDiagnosesListPayload,
): Promise<Choice[]> => {
  const url = `${API_URL}DajZoznamDiagnoz`;
  const { data }: AxiosResponse<GetDiagnosesListReponse> = await axios.post(
    url,
    payload,
  );
  const response = data?.diagnozy?.map((diag: ProposalDiagnose) => ({
    label: `${diag.kod} - ${diag.nazov}`,
    value: diag.kod,
  }));
  return response || [];
};
