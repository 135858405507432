import React from 'react';
import SafeHtml from '../../../../../components/SafeHtml/SafeHtml';
import { CustomTooltip } from '../../../../../components/CustomTooltip';
import { IconInfo } from '../../../../../components';
import { color } from '@dovera/design-system';

interface LabelProps {
  id: string;
  label: React.ReactNode;
  tooltip?: string;
  tooltipClass?: string;
}

const Label = ({ id, label, tooltip, tooltipClass }: LabelProps) => (
  <>
    <SafeHtml html={label} />
    {tooltip && (
      <span className={tooltipClass}>
        <CustomTooltip
          dialog={<SafeHtml html={tooltip} />}
          id={`tooltip-radio--${id}`}
        >
          <IconInfo
            color={color('black')}
            height={18}
            id={`radio-icon-info--${id}`}
            width={18}
          />
        </CustomTooltip>
      </span>
    )}
  </>
);

export default Label;
