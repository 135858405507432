import {
  Button,
  ButtonLink,
  ButtonSecondary,
  Icon,
  color,
} from '@dovera/design-system';
import { navrhyRoutes } from '../../../../routes';
import {
  formatNameStr,
  hotjarMasking,
  stringWithSpaces,
} from '../../../../utils/strings.utils';
import { formatDate } from '../../../../utils/date.utils';
import { cx } from '../../../../utils/exports';
import { CustomTooltip } from '../../../../components/CustomTooltip';
import strings from '../../../../constants/strings';
import { ProposalDetailQueries } from '../../../../types/spaProposals.types';
import { useNavigate } from 'react-router';
import { createViewUrl } from '../../../../utils/app.utils';
import IconBlueUsers from '../../../../components/CustomIcons/IconBlueUsers';
import IconStethoscope from '../../../../components/CustomIcons/IconStethoscope';
import SortableTable from '../../../../components/SortableTable/SortableTable';

interface Props {
  data: {
    doctor: {
      code: string;
      firstName: string;
      lastName: string;
      titleAfter: string | null;
      titleBefore: string | null;
    };
    id: number;
    insuree: {
      firstName: string;
      insureeId: string;
      lastName: string;
      /**
       * A = Aktivny
       * U = Umrtie
       * K = Ukonceny
       */
      state: 'A' | 'U' | 'K' | string;
    };
    proposalNumber: string;
    proposalType: 'A' | 'B' | string | null;
    reservation: {
      from: string;
      to: string;
    } | null;
    validity: {
      from: string;
      to: string;
    };
  }[];
  // eslint-disable-next-line
  onChooseDateClick: (proposalNumber: number) => void;
}

const texts = strings.proposals.spa.list.approvedProposals;

const TableList = ({ data, onChooseDateClick }: Props) => {
  const navigate = useNavigate();
  return (
    <SortableTable
      cols={[
        {
          index: 1,
          name: 'Návrh',
          isSortable: true,
          sortType: 'alphabetical-desc',
        },
        {
          index: 2,
          name: (
            <span className="d-flex align-center">
              <IconBlueUsers height={16} id={`icon-pois-label`} width={16} />
              <span className="text-space-half-left">Pacient</span>
            </span>
          ),
          isSortable: true,
          sortType: 'alphabetical',
        },
        {
          index: 3,
          name: (
            <span className="d-flex align-center">
              <IconStethoscope id={`icon-diagnose-label`} isBold />
              <span className="text-space-half-left">Kód/meno lekára</span>
            </span>
          ),
          isSortable: true,
          sortType: 'alphabetical',
        },
        {
          index: 4,
          name: 'Platnosť návrhu',
          isSortable: true,
          sortType: 'date',
        },
        {
          index: 5,
          name: 'Rezervácia kúpeľov',
        },
        {
          index: 6,
          name: '',
        },
      ]}
      rows={data.map((d) => {
        const queries: ProposalDetailQueries = {
          cisloNavrhu: d.proposalNumber,
          id: d.id,
        };
        return {
          col1: {
            sortableValue: d.proposalNumber,
            value: (
              <>
                {d.insuree.state === 'A' ? (
                  <Button
                    className="text-normal no-mrg no-pad"
                    onClick={() =>
                      navigate(
                        createViewUrl(
                          navrhyRoutes.zoznamSchvalenychNavrhov,
                          queries,
                        ),
                      )
                    }
                    type="link-secondary"
                  >
                    {d.proposalNumber}
                  </Button>
                ) : (
                  d.proposalNumber
                )}
                <br />
                <span>
                  Typ: <b>{d.proposalType || '-'}</b>
                </span>
              </>
            ),
          },
          col2: {
            sortableValue: d.insuree.lastName,
            value: (
              <span data-hj-masked>
                {d.insuree.insureeId}
                <b
                  className={cx(
                    'd-block',
                    d.insuree.state !== 'A' && 'text-color-error-base',
                  )}
                >
                  <span className="d-flex text-justify">
                    {hotjarMasking(
                      formatNameStr(
                        stringWithSpaces([
                          d.insuree.firstName,
                          d.insuree.lastName,
                        ]),
                      ),
                    )}
                    {d.insuree.state !== 'A' && (
                      <span className="text-space-half-left">
                        <CustomTooltip
                          dialog={texts.tooltips.inactive}
                          id={`tooltip-help--${d.proposalNumber}`}
                        >
                          <Icon color={color('error')} name="16-help" />
                        </CustomTooltip>
                      </span>
                    )}
                  </span>
                </b>
              </span>
            ),
          },
          col3: {
            sortableValue: d.doctor.lastName,
            value: (
              <span data-hj-masked>
                {d.doctor.code}
                {hotjarMasking(
                  formatNameStr(
                    stringWithSpaces([
                      d.doctor.titleBefore || '',
                      d.doctor.firstName,
                      `${d.doctor.lastName}${d.doctor.titleAfter ? ',' : ''}`,
                      d.doctor.titleAfter || '',
                    ]),
                  ),
                  'b',
                  'd-block',
                )}
              </span>
            ),
          },
          col4: {
            sortableValue: d.validity.from,
            value: (
              <span>
                {formatDate(d.validity.from)}
                <br />
                {formatDate(d.validity.to)}
              </span>
            ),
          },
          col5: {
            value: d.reservation ? (
              <span>
                {formatDate(d.reservation.from)}
                <br />
                {formatDate(d.reservation.to)}
              </span>
            ) : d.insuree.state !== 'A' ? (
              '-'
            ) : (
              <ButtonSecondary
                className="no-mrg"
                onClick={() => onChooseDateClick(d.id)}
                size="s"
              >
                {texts.labels.date}
              </ButtonSecondary>
            ),
          },
          col6: {
            value:
              d.insuree.state === 'A' ? (
                <ButtonLink
                  className="no-mrg no-pad"
                  onClick={() =>
                    navigate(
                      createViewUrl(
                        navrhyRoutes.zoznamSchvalenychNavrhov,
                        queries,
                      ),
                    )
                  }
                >
                  Detail
                  <Icon
                    className="text-space-half-left"
                    color={color('primary', 600)}
                    name="16-chevron-right"
                  />
                </ButtonLink>
              ) : (
                '-'
              ),
          },
        };
      })}
    />
  );
};

export default TableList;
