import {
  DostupnaOdbornost,
  OdbornostHodnoteneho,
} from '../types/poskytovatel.types';
import routes, {
  cakackyIndex,
  diagnozyRoutes,
  kapRoute,
  zuctovanieIndex,
} from '../routes';
import { EPSections } from '../constants/systemConstants';
import store from '../store';
import { userHasSection } from './app.utils';
import {
  zmenEmptyStateOdbornosti,
  zmenOdbornost,
} from '../slices/poskytovatel.slice';

export function availableExpertises(
  expertises: OdbornostHodnoteneho[],
  expertise?: string,
  typeZs?: string,
): OdbornostHodnoteneho[] {
  if (!expertises || !expertises.length) return [];
  const availableExp: OdbornostHodnoteneho[] = [];
  expertises.forEach(
    (exp) =>
      exp.kodOdbNz &&
      availableExp.push({
        ...exp,
        aktivna:
          expertise && typeZs
            ? exp.kodOdbNz === expertise && exp.kodTypZS === typeZs
            : exp.predvolenaNZ === '1',
        povolenyVstupHK: !!exp.kodOdbNz,
        povolenyVstupPP: !!exp.kodOdbNz,
      }),
  );
  if (!availableExp.some((e) => e.aktivna)) {
    availableExp[0].aktivna = true;
  }
  return availableExp;
}

export function changeActiveExpertise(
  kodOdbNz: string,
  kodTypZS: string,
  expertises: OdbornostHodnoteneho[],
): OdbornostHodnoteneho[] {
  const oldExp = expertises.map((e) => ({ ...e, aktivna: false }));
  return oldExp.map((exp) => ({
    ...exp,
    aktivna: exp.kodOdbNz === kodOdbNz && exp.kodTypZS === kodTypZS,
  }));
}

export function changeActiveDefaultExpertise(
  kodOdbNZ: string,
  kodTypZS: string,
  expertises: OdbornostHodnoteneho[],
): OdbornostHodnoteneho[] {
  return expertises.map((exp) => {
    const isActive: boolean = !!(
      kodOdbNZ &&
      kodOdbNZ.includes(exp.kodOdbNz) &&
      exp.kodTypZS === kodTypZS
    );
    return {
      ...exp,
      predvolenaNZ: isActive ? '1' : '0',
      aktivna: isActive,
    };
  });
}

export function visibleAllParameters(active: DostupnaOdbornost): boolean {
  if (!active) return true;
  const code = parseInt(active.kodOdbNz, 10);
  return code < 141 || code > 144;
}

export const isVAS = (typZs: string): boolean =>
  ['101', '102', '103'].includes(typZs);

export const hideDropdownExpertises = (): boolean => {
  const location: string = window.location.pathname;
  const disabledLocations: string[] = [
    routes.zmluvyDodatky,
    routes.profil,
    routes.autentifikacia,
    kapRoute,
    routes.spravy,
    zuctovanieIndex,
    cakackyIndex,
  ];
  return disabledLocations.some((l) => location.includes(l));
};

export const hasSection = (
  epSection: EPSections,
  theme: 'Zuctovanie' | 'CakacieListiny' | 'Navrhy',
): boolean => {
  const { temy } = store.getState().poskytovatel;
  const sections = temy?.find((s) => s.nazov === theme)?.sekcie;
  if (!sections) return false;
  return !!sections.includes(epSection as never);
};

export const hideEpDashboardLinks = (): boolean =>
  !(userHasSection('FKT') && !hasSection(EPSections.Fkt, 'Zuctovanie')) &&
  !(userHasSection('ZUC') && !hasSection(EPSections.Zuc, 'Zuctovanie')) &&
  !userHasSection('MAST') &&
  !userHasSection('NAVZS');

export const getExpertises = (
  activeExpertise: OdbornostHodnoteneho,
  expertises: OdbornostHodnoteneho[],
  location: string,
): { isEmptyState: boolean; items: OdbornostHodnoteneho[] } => {
  let expResult: OdbornostHodnoteneho[] = expertises;
  if (location.includes(routes.parameters) || location === routes.dashboard)
    expResult = expertises.filter((e) => e.kodOdbNz && e.povolenyVstupHK);
  if (location.includes(routes.pp) || location.includes(diagnozyRoutes.index))
    expResult = expertises.filter((e) => e.kodOdbNz && e.povolenyVstupPP);
  if (location.includes(routes.manazerskyPrehladZmluv))
    expResult = expertises.filter((e) => e.kodOdbNz && e.povolenyVstupPCP);
  if (
    expResult.every((e) => !e.aktivna) &&
    (expResult.some(
      (e) =>
        e.kodOdbNz === activeExpertise?.kodOdbNz &&
        e.kodTypZS === activeExpertise?.kodTypZS,
    ) ||
      location === routes.dashboard)
  ) {
    expResult = expResult.map((e, i) => ({ ...e, aktivna: i === 0 }));
    if (expResult?.length)
      store.dispatch(
        zmenOdbornost({
          value: expResult[0].kodOdbNz,
          kodTypZS: expResult[0].kodTypZS,
          userAction: true,
        }),
      );
  }
  return {
    isEmptyState: !expResult.some(
      (e) =>
        e.kodOdbNz === activeExpertise?.kodOdbNz &&
        e.kodTypZS === activeExpertise?.kodTypZS,
    ),
    items: expResult,
  };
};

export const handleChangeExpertise = ({
  expertises,
  isEmptyState,
  pathname,
}: {
  expertises: OdbornostHodnoteneho[];
  isEmptyState: boolean;
  pathname: string;
}) => {
  if (isEmptyState) {
    if (pathname === routes.dashboard || pathname === routes.callback) {
      const availableExp = expertises.find((o) => o.povolenyVstupHK);
      if (!availableExp) return null;
      return store.dispatch(
        zmenOdbornost({
          value: availableExp?.kodOdbNz,
          kodTypZS: availableExp?.kodTypZS,
          userAction: true,
        }),
      );
    }
    return store.dispatch(zmenEmptyStateOdbornosti({ emptyState: true }));
  }
  if (store.getState().poskytovatel.odbornostiEmptyState !== isEmptyState)
    return store.dispatch(zmenEmptyStateOdbornosti({ emptyState: false }));
  return null;
};
