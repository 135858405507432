import { color as ddsColor, getRem } from '@dovera/design-system';
import { createUseStyles } from 'react-jss';

interface StylesProps {
  color?: string;
}

export default createUseStyles({
  divider: ({ color }: StylesProps) => ({
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    '& span': {
      margin: `0 ${getRem(8)}`,
      color: color || ddsColor('grey', 500),
      fontWeight: 400,
    },
    '&::before, &::after': {
      content: '""',
      flex: 1,
      borderBottom: `2px solid ${color || ddsColor('grey', 100)}`,
      marginBottom: getRem(9),
    },
  }),
});
