import useStyles from './InsureeBar.styles';
import { cx } from '../../../../../../utils/exports';
import { hotjarMasking } from '../../../../../../utils/strings.utils';
import IconCheck from '../../../../../../components/CustomIcons/IconCheck';
import strings from '../../../../../../constants/strings';
import { getPatientIcon } from '../../../../../../utils/pacienti.utils';
import { FormVariant } from '../../../../../../types/proposals.types';
import IconClose from '../../../../../../components/CustomIcons/IconClose';
import { color } from '@dovera/design-system';

const texts = strings.proposals.spa.new;

interface Props {
  address: string;
  dateOfBirth: string;
  mobile: string;
  name: string;
  sex: 'M' | 'Z' | string;
  variant: FormVariant;
}

const InsureeBar = ({
  address,
  dateOfBirth,
  mobile,
  name,
  sex,
  variant,
}: Props) => {
  const classes = useStyles(variant);
  const id = 'insureeBar--icon';

  return (
    <div className={cx(classes.insureeBar)}>
      <div>{getPatientIcon(sex, dateOfBirth)}</div>
      <div>
        <span>
          {`${texts.labels.name}: `}
          {hotjarMasking(name, 'b')}
        </span>
        <span>
          {`${texts.labels.address}: `}
          {hotjarMasking(address, 'b')}
        </span>
        <span>
          {`${texts.labels.mobile}: `}
          {hotjarMasking(mobile, 'b')}
        </span>
      </div>
      <div className={classes.statusIcon}>
        {variant === FormVariant.Success ? (
          <IconCheck color={color(variant)} id={id} />
        ) : (
          <IconClose color={color(variant)} id={id} />
        )}
      </div>
    </div>
  );
};

export default InsureeBar;
