import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { checkSystemsStatus, getSystemNotifications } from '../api';
import strings from '../constants/strings';
import { SystemovyParameterObjekt } from '../types/app.types';
import { LoadingState, Nullable } from '../types';
import { dajSystemoveParametre } from '../api/poskytovatel';
import { GetSystemNotificationsResponse } from '../types/api';
import { OznamEP } from '../types/models/OznamEP';
import moment from 'moment';

interface AppState {
  apiResponseError: {
    code: number;
    endpoint: string | null;
  } | null;
  epNotifications: {
    data: OznamEP[];
    lastLoaded: string;
  };
  error: string;
  ip: string;
  isExternalSystemOffline: boolean;
  isLoadingSystemStatus: boolean;
  oznamyEP: string[];
  prevRoute: string | null;
  stavEP: 1 | 0;
  stavPripojenia: boolean;
  systemoveParametre: {
    dataState: LoadingState;
    parameters: Nullable<{
      [key: string]: SystemovyParameterObjekt[];
    }>;
  };
}

export const initialState: AppState = {
  apiResponseError: null,
  epNotifications: {
    data: [],
    lastLoaded: '',
  },
  error: '',
  ip: '',
  isExternalSystemOffline: false,
  isLoadingSystemStatus: false,
  oznamyEP: [],
  prevRoute: null,
  stavEP: 1,
  stavPripojenia: true,
  systemoveParametre: {
    dataState: LoadingState.none,
    parameters: null,
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(dajSystemoveParametre.pending.toString(), (state) => {
        state.systemoveParametre.dataState = LoadingState.fetching;
      })
      .addCase(
        dajSystemoveParametre.fulfilled.toString(),
        (state, action: any) => ({
          ...state,
          systemoveParametre: {
            dataState: LoadingState.filled,
            parameters: {
              [action.meta.arg.nazovSekcie]: action.payload.systemoveParametre,
            },
          },
        }),
      )
      .addCase(checkSystemsStatus?.pending?.toString(), (state) => ({
        ...state,
        error: '',
        isLoadingSystemStatus: true,
      }))
      .addCase(
        checkSystemsStatus?.fulfilled?.toString(),
        (
          state,
          action: PayloadAction<{
            systemy: { name: string; online: boolean }[];
          }>,
        ) => ({
          ...state,
          isLoadingSystemStatus: false,
          isExternalSystemOffline: action.payload?.systemy?.some(
            (s) => !s.online,
          ),
        }),
      )
      .addCase(checkSystemsStatus?.rejected?.toString(), (state) => ({
        ...state,
        error: strings.defaultError,
        isExternalSystemOffline: true,
        isLoadingSystemStatus: false,
      }))
      .addCase(
        getSystemNotifications.fulfilled.toString(),
        (state, action: PayloadAction<GetSystemNotificationsResponse>) => {
          state.epNotifications = {
            data: action.payload.oznamy,
            lastLoaded: moment().format(),
          };
        },
      );
  },
  reducers: {
    checkInternetConnection(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        stavPripojenia: action.payload,
      };
    },
    setOznamyEP(state, action: PayloadAction<string[]>) {
      return {
        ...state,
        oznamyEP: action.payload,
      };
    },
    storeIP(state, action: PayloadAction<string>) {
      return {
        ...state,
        ip: action.payload,
      };
    },
    setApiResponseError(
      state,
      action: PayloadAction<{ code: number; endpoint: string | null }>,
    ) {
      const { code, endpoint } = action.payload;
      return {
        ...state,
        apiResponseError:
          code === 200
            ? null
            : {
                code,
                endpoint,
              },
      };
    },
    setPrevRoute(state, action: PayloadAction<{ route: string }>) {
      return {
        ...state,
        prevRoute: action.payload.route,
      };
    },
    setSystemOffline(state) {
      return { ...state, isExternalSystemOffline: true };
    },
  },
});

export const {
  checkInternetConnection,
  setApiResponseError,
  setOznamyEP,
  setPrevRoute,
  setSystemOffline,
  storeIP,
} = appSlice.actions;
export default appSlice.reducer;
