import useStyles from '../../Proposals.styles';
import { Grid, GridCol, Skeleton } from '@dovera/design-system';
import { cx } from '../../../../utils/exports';

const SkeletonLoaderHeader = () => {
  const classes = useStyles({ proposalType: 'KN' });
  return (
    <Grid>
      <GridCol size={6}>
        <div className={cx(classes.proposalHeaderRow, 'mb')}>
          <Skeleton width="40%" />
        </div>
        <div className={cx(classes.proposalHeaderRow, 'mb')}>
          <Skeleton width="30%" />
        </div>
        <div className={cx(classes.proposalHeaderRow, 'mb')}>
          <Skeleton width="20%" />
        </div>
        <div className={cx(classes.proposalHeaderRow, 'mb')}>
          <Skeleton width="45%" />
        </div>
        <div className={cx(classes.proposalHeaderRow, 'mb')}>
          <Skeleton height={48} width="30%" />
        </div>
      </GridCol>
      <GridCol size={6}>
        <div className={cx(classes.proposalHeaderRow, 'mb')}>
          <Skeleton width="70%" />
        </div>
        <div className={cx(classes.proposalHeaderRow, 'mb')}>
          <Skeleton width="30%" />
        </div>
      </GridCol>
    </Grid>
  );
};

export default SkeletonLoaderHeader;
