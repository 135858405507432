import { MouseEvent, ReactNode } from 'react';
import { publicIpv4 } from 'public-ip';
import routes, { diagnozyRoutes } from '../routes';
import { ns } from './object.utils';
import { jwtDecode } from 'jwt-decode';
import { DefaultType, EPSection, StringMap } from '../types';
import { createQueryParams } from './api.utils';
import store from '../store';
import { SystemoveParametreNazovConfigu } from '../types/api/poskytovatel';
import { MenuItemDropdown, toggleItem } from '../slices/menu.slice';
import { history } from '../helpers/history';

export const isOnline = () => window.navigator.onLine;

export function showModal(modalRef) {
  ns(() => {
    // @ts-ignore
    modalRef.current.modal.current.show();
  });
}

export function hideModal(modalRef) {
  ns(() => {
    // @ts-ignore
    modalRef.current.modal.current.hide();
  });
}

// Detect IE browser
const ua = navigator.userAgent;
export const isIE = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

export const isHPUrl = (path: string): boolean =>
  path.includes('/kvalita') ||
  path.includes('/efektivnost') ||
  path.includes('/inovacie');

// eslint-disable-next-line
export const sleep = (m) => new Promise((r) => setTimeout(r, m));

export const scrollToTop = (top?: number) => {
  // @ts-ignore
  document
    .querySelector('body')
    .scrollTo({ top: top || 0, left: 0, behavior: 'smooth' });
};

export const scrollToModalTop = () => {
  // @ts-ignore
  document
    .querySelector('.dialog.is-active .modal__content')
    ?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};

export const scrollTo = (top: number) => {
  const headerHeight = 71;
  // @ts-ignore
  const currentScroll = document.querySelector('body')?.scrollTop || 0;
  if (top) {
    // @ts-ignore
    document.querySelector('body').scrollTo({
      top: currentScroll + top - headerHeight,
      left: 0,
      behavior: 'smooth',
    });
  }
};

export const positionAfterCloseModal = () => {
  const position =
    Number(document.querySelector('body')?.dataset?.lockScrolltop) || 0;
  document.querySelector('body')?.scrollTo({ top: position, left: 0 });
};

export const getUserAgent = (): string => '';

// eslint-disable-next-line
export const getIP = async (): Promise<string> => await publicIpv4();

export const getPageTitle = (): string => {
  const location = window.location.href;
  if (
    location.includes(`${routes.parameters}/`) ||
    location.includes(`${routes.prehladHPPublic}/`)
  )
    return 'Detail hodnotiacich parametrov';
  if (
    location.includes(routes.parameters) ||
    location.includes(routes.prehladHPPublic)
  )
    return 'Prehľad hodnotiacich parametrov';
  if (location.includes(routes.pdp)) return 'Prehľad diagnostiky pacientov';
  if (location.includes(routes.ppFarmakoterapia))
    return 'Farmakoterapia Vašich pacientov';
  if (location.includes(routes.ppChronicki))
    return 'Chronickí pacienti vo Vašom kmeni';
  if (location.includes(routes.ppCerpanieZS))
    return 'Čerpanie zdravotnej starostlivosti';
  if (location.includes(routes.pp)) return 'Prehľad pacientov';
  if (location.includes(diagnozyRoutes.index)) return 'Prehľad diagnóz';
  return 'Dashboard';
};

/** Funkcia pozera, ci kliknuty objekt vramci klikatelneho rodica nie je klikatelny (napr klikatelna karta v ktorej je tlacidlo) */
export const isClickablePartOfElement = (
  e: MouseEvent<HTMLElement>,
): boolean => {
  // @ts-ignore
  const { nodeName } = e.target;
  // @ts-ignore
  const parentNodeName = e.target?.parentNode?.nodeName;
  // @ts-ignore
  const doubleParentNodeName = e.target?.parentNode?.parentNode?.nodeName;

  const notAllowedNodeNames: string[] = ['BUTTON'];

  return (
    nodeName &&
    parentNodeName &&
    doubleParentNodeName &&
    (notAllowedNodeNames.indexOf(nodeName) > -1 ||
      notAllowedNodeNames.indexOf(parentNodeName) > -1 ||
      notAllowedNodeNames.indexOf(doubleParentNodeName) > -1)
  );
};

export const isActiveRoute = (route: string): boolean => {
  const pathName = window.location.pathname;
  if (
    (route === routes.dashboard || !route) &&
    (pathName === routes.dashboard || !pathName)
  )
    return true;

  return pathName.includes(route);
};

export const isLessThen10SecondsFromLogin = (token: string): boolean => {
  if (!token) return false;
  const jwt: string = jwtDecode(token);
  // @ts-ignore
  return (jwt?.auth_time || 0) + 10 >= (jwt?.nbf || 0);
};

export const stopVideos = () => {
  // @ts-ignore
  const videos = document.querySelectorAll('iframe, video');
  videos.forEach((video) => {
    if (video.tagName.toLowerCase() === 'video') {
      // @ts-ignore
      video.pause();
    } else {
      // @ts-ignore
      const { src } = video;
      // @ts-ignore
      video.src = src;
    }
  });
};

export const getIdFromUrl = (pathname: string): number => {
  if (!pathname.includes('/')) return 0;
  const urlParts = pathname.split('/');
  if (!urlParts[urlParts.length - 1]) {
    if (!Number.isNaN(urlParts[urlParts.length - 2]))
      return Number(urlParts[urlParts.length - 2]);
  }
  if (Number.isNaN(urlParts[urlParts.length - 1])) return 0;
  return Number(urlParts[urlParts.length - 1]);
};

export const createViewUrl = (
  pathname: string,
  queryParams?: StringMap<string | number | undefined>,
): string => {
  const { origin } = window.location;
  const queries = createQueryParams({
    withoutEmpty: false,
    queryParams: queryParams || {},
  });
  if (!pathname) return '';
  if (pathname.includes(origin))
    return `${pathname.split(origin)[1]}${queries}`;
  return `${pathname}${queries}`;
};

export const userHasSection = (
  section: EPSection,
  jwt?: string | null,
): boolean => {
  const { token } = store?.getState().auth || '';
  if (!token && !jwt) return false;
  // return (
  //   store
  //     .getState()
  //     .poskytovatel.temy?.find((t) => t.nazov === 'Sekcia')
  //     ?.sekcie?.includes(section as never) || false
  // );
  // @ts-ignore
  return !!jwtDecode(token || jwt)?.dzp_user_role_section?.includes(section);
};

export const scrollPositionAfterModalVisible = () => {
  // @ts-ignore
  document.querySelector('body').scrollTo(
    0,
    // @ts-ignore
    document.querySelector('body')?.dataset?.lockScrolltop || 0,
  );
};

export const hideFooter = (hide: boolean) => {
  // @ts-ignore
  const element = document.querySelector('footer');
  if (element) {
    element.style.display = hide ? 'none' : 'block';
  }
};

export const getScrollPosition = () =>
  // @ts-ignore
  document.querySelector('body').scrollTop || 0;

export const lockScrollTopModal = (scrollPosition: number) => {
  // @ts-ignore
  document.querySelector('body').dataset.lockScrolltop = scrollPosition;
};

export const canShowSection = (userSection: string): boolean => {
  const { odbornosti } = store.getState().poskytovatel;
  const kodyOdbornosti = odbornosti.map((o) => o.kodOdbNz);
  if (userSection === 'KAP')
    return kodyOdbornosti.some((o) => ['020', '008', '009', '016'].includes(o));
  return false;
};

export const isSTAT = (): boolean => {
  const STATsection = store.getState().auth.isSTAT;
  const currentUserSTAT = store.getState().poskytovatel.jeStatutarNaPristupe;
  return !!(STATsection && currentUserSTAT);
};

export const hideTooltips = () => {
  document.querySelectorAll('.tooltip').forEach((t) => {
    t.setAttribute('aria-hidden', 'true');
  });
};

/**
 * Zmena viditelnosti elementov cez pointer-events
 * @date 6. 4. 2023 - 15:52:40
 *
 * @param {?boolean} [visible]
 */
export const appActionsVisibility = (visible?: boolean) => {
  document.querySelectorAll('button.tab').forEach((t) => {
    // @ts-ignore
    t.style.pointerEvents = visible ? 'auto' : 'none';
  });
};

/**
 * Scroll vrámci modálneho okna (default top alebo bottom pozícia)
 * @date 19. 4. 2023 - 16:08:45
 *
 * @param {('top' | 'bottom')} to
 */
export const modalScroll = (to: 'top' | 'bottom') => {
  const activeEl = document.activeElement;
  const modalWrapper = activeEl?.closest('.modal');
  if (modalWrapper) {
    const contentEl = modalWrapper.querySelector('.modal__content');
    const scrollTo = to === 'top' ? 0 : contentEl?.clientHeight || 1000;
    contentEl?.scroll({ top: scrollTo, behavior: 'smooth' });
  }
};

/**
 * Metóda evokuje ternárnu podmienku
 * @date 8. 5. 2023 - 17:32:38
 *
 * @param {boolean} condition
 * @param {DefaultType} truthy
 * @param {DefaultType} falsy
 * @returns {DefaultType}
 * @remarks
 * 8.5.2023 - Vytvorenie metódy pre lepšiu čitateľnosť kódu kvôli statickej analýze (sonar)
 */
export const inlineCondition = (
  condition: boolean,
  truthy: DefaultType,
  falsy: DefaultType,
): DefaultType => (condition ? truthy : falsy);

/**
 * Metóda evokuje ternárnu podmienku, ktora vrati string (pouzitelna pre routes)
 * @date 24. 5. 2023 - 9:34:05
 *
 * @param {boolean} condition
 * @param {DefaultType} truthy
 * @param {DefaultType} falsy
 * @returns {string}
 */
export const inlineStrCondition = (
  condition: boolean,
  truthy: DefaultType,
  falsy: DefaultType,
): string => (condition ? truthy : falsy)?.toString() || '';

export const inlineStrNullCondition = (
  condition: boolean,
  truthy: DefaultType,
  falsy: DefaultType,
): string | null => {
  const resp = condition ? truthy : falsy;
  if (!resp) return null;
  return resp?.toString() || '';
};

/**
 * Metóda evokuje ternárnu podmienku, ktora vrati cislo (pouzitelna pre routes)
 * @date 24. 5. 2023 - 12:33:02
 *
 * @param {boolean} condition
 * @param {DefaultType} truthy
 * @param {DefaultType} falsy
 * @returns {number}
 */
export const inlineNumberCondition = (
  condition: boolean,
  truthy: DefaultType,
  falsy: DefaultType,
): number => Number(condition ? truthy : falsy);

export const safeNode = (
  node?: ReactNode | boolean | string,
  condition?: boolean,
  emptyTag?: ReactNode,
): ReactNode => {
  if (node && !!condition === true) return node;
  return emptyTag || <span />;
};

export const getUserId = (): number => store.getState().auth.pouzivatelId || 0;

export const getVZ = (): number => store.getState().auth.vztahId || 0;

export const timeout = (ms: number): Promise<any> =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const getSysParamValue = (
  section: SystemoveParametreNazovConfigu,
  paramName: string,
): undefined | string | number => {
  const { systemoveParametre } = store.getState().app;
  if (!systemoveParametre) return undefined;
  if (!systemoveParametre[section]) return undefined;
  return systemoveParametre[section].find((sp) => sp?.nazov === paramName)
    ?.hodnota;
};

/**
 * App navigation with change menu state
 * @date 3/12/2024 - 11:16:21 AM
 *
 * @param {{
 *   menuItem?: MenuItemDropdown;
 *   path: string;
 * }} param0
 * @param {MenuItemDropdown} param0.menuItem
 * @param {string} param0.path
 */
export const navigateApp = ({
  menuItem,
  path,
}: {
  menuItem?: MenuItemDropdown;
  path: string;
}) => {
  if (menuItem) store.dispatch(toggleItem(menuItem));
  history.navigate(path);
};

export const isAppReloaded = (): boolean =>
  store?.getState()?.app?.prevRoute === routes.callback;

export const isTechnicalError = (): boolean => {
  const statusCode: number = Number(
    store.getState()?.app.apiResponseError?.code,
  );
  return statusCode === 400 || statusCode >= 500;
};

export const scrollToBottom = () => {
  let offsetEl = 0;
  const elements = document.querySelectorAll('input, textarea, select');
  elements.forEach((e) => {
    // @ts-ignore
    if (!offsetEl && e.value.trim() === '') {
      const clientPosition = e.getBoundingClientRect().top;
      offsetEl = clientPosition ? clientPosition + window.scrollY - 100 : 0;
    }
  });
  scrollTo(offsetEl || document.body.scrollHeight);
  // window.scrollTo({
  //   top: offsetEl || document.body.scrollHeight,
  //   behavior: 'smooth',
  // });
};
