import { ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import routes, { diagnozyRoutes, kapRoute, navrhyRoutes } from '../../routes';
import { IconMenuDashboard, IconMenuPatient, IconUsers } from '../CustomIcons';
import {
  MenuItemDropdown,
  toggleItem,
  toggleMenu,
  wrapAllMenuItems,
} from '../../slices/menu.slice';
import IconEServices from '../CustomIcons/IconEServices';
import { RootState } from '../../rootReducer';
import IconDoctor from '../CustomIcons/IconDoctor';
import Navigation, { NavigationProps } from '../Navigation/Navigation';
import { useLocation } from 'react-router';
import IconMenuContracts from '../CustomIcons/IconMenuContracts';
import {
  convertMenuItemFromGroupTitle,
  getParametersMenuItems,
} from '../../utils/parameters.utils';
import IconQualityMenu from '../CustomIcons/IconQualityMenu';
import IconEfectivityMenu from '../CustomIcons/IconEfectivityMenu';
import IconInovationMenu from '../CustomIcons/IconInovationMenu';
import { canShowPatientTypes } from '../../utils/pacienti.utils';
import { useWindowSize } from '../../hooks/useWindowSize';
import { breakpoints } from '@dovera/design-system';
import IconMenuParameters from '../CustomIcons/IconMenuParameters';
import IconNewMessage from '../CustomIcons/IconNewMessage';
import { canShowSection, userHasSection } from '../../utils/app.utils';
import { useAppDispatch } from '../../hooks/useStore';
import IconMail from '../CustomIcons/IconMail';
import { refresh } from '../../slices/spravy.slice';
import IconMoneyInsert from '../CustomIcons/IconMoneyInsert';
import { epSekcie } from '../../constants/epLinks';
import { EPSections } from '../../constants/systemConstants';
import { hasSection } from '../../utils/poskytovatel.utils';
import IconHourglasses from '../CustomIcons/IconHourglasses';
import IconCalc from '../CustomIcons/IconCalc';
import strings from '../../constants/strings';
import { noAccess } from '../../utils/auth.utils';
import { newProposalAccess } from '../../utils/proposals.utils';

const { menu, submenu } = strings.app;

const menuIconHP = (title: string): ReactNode => {
  if (title.toLowerCase().includes('kvalita'))
    return <IconQualityMenu id="icon-qualityMenu" />;
  return title.toLowerCase().includes('efekt') ? (
    <IconEfectivityMenu id="icon-efectivity-menu" />
  ) : (
    <IconInovationMenu id="icon-inovation-menu" />
  );
};

const Menu = () => {
  const dispatch = useAppDispatch();
  const { hasPZSNAKL, isASOC, token } = useSelector(
    (state: RootState) => state.auth,
  );
  const { menuParameters } = useSelector(
    (state: RootState) => state.parameters,
  );
  const { isOpen, openedItems } = useSelector((state: RootState) => state.menu);
  const { kodyHodnoty } = useSelector(
    (state: RootState) => state.pacienti.prehladPacientov.prehlad,
  );
  const { isLoaded, odbornosti } = useSelector(
    (state: RootState) => state.poskytovatel,
  );

  const location = useLocation();
  const windowSize = useWindowSize();

  const aktivnaOdbornost = odbornosti.filter((d) => d.aktivna)[0];
  useEffect(() => {
    /** call only with reload */
    /** wrap all menu items and unwrap only correct subitems, which child is currently active */
    if (
      (location.pathname.includes(routes.parameters) ||
        location.pathname.includes(routes.pp) ||
        location.pathname.includes(routes.pdp) ||
        location.pathname.includes(navrhyRoutes.index) ||
        location.pathname.includes(kapRoute) ||
        location.pathname.includes(diagnozyRoutes.index)) &&
      !isLoaded
    ) {
      dispatch(wrapAllMenuItems());
      if (location.pathname.includes(routes.parameters)) {
        dispatch(toggleItem(MenuItemDropdown.zdravotnaStarostlivost));
        dispatch(toggleItem(MenuItemDropdown.parametre));
        if (location.pathname.includes('kvalita'))
          dispatch(toggleItem(MenuItemDropdown.kvalita));
        if (location.pathname.includes('efektivnost'))
          dispatch(toggleItem(MenuItemDropdown.efektivnost));
        if (location.pathname.includes('inovacie'))
          dispatch(toggleItem(MenuItemDropdown.inovacie));
      }
      if (
        location.pathname.includes(routes.pp) ||
        location.pathname.includes(routes.pdp) ||
        location.pathname.includes(diagnozyRoutes.index)
      ) {
        dispatch(toggleItem(MenuItemDropdown.zdravotnaStarostlivost));
        dispatch(toggleItem(MenuItemDropdown.pacienti));
        if (location.pathname.includes(routes.pp))
          dispatch(toggleItem(MenuItemDropdown.prehladPacientov));
      }
      if (location.pathname.includes(navrhyRoutes.index)) {
        dispatch(toggleItem(MenuItemDropdown.navrhy));
      }
      if (location.pathname.includes(kapRoute))
        dispatch(toggleItem(MenuItemDropdown.pacienti));
    }
    // eslint-disable-next-line
  }, [location.pathname, isLoaded]);
  useEffect(() => {
    if (windowSize.width < breakpoints.l && isOpen) dispatch(toggleMenu());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.pathname, windowSize.width]);

  const pacientiRoutes: NavigationProps[] = [];
  if (canShowPatientTypes('ZS', kodyHodnoty))
    pacientiRoutes.push({
      route: routes.ppCerpanieZS,
      title: submenu.zs,
    });
  if (canShowPatientTypes('FARM', kodyHodnoty))
    pacientiRoutes.push({
      route: routes.ppFarmakoterapia,
      title: submenu.farm,
    });
  if (canShowPatientTypes('CHR', kodyHodnoty))
    pacientiRoutes.push({
      route: routes.ppChronicki,
      title: submenu.chr,
    });
  /** Ak nema ziadne povolene sekcie alebo token */
  if (noAccess(!token || location.pathname.includes(routes.autentifikacia)))
    return <div />;

  /** Spolocna navigacia */
  const dashboardNav: NavigationProps = {
    route: routes.dashboard,
    title: (
      <span>
        <IconMenuDashboard id="icon-menu-dashboard" /> {menu.home}
      </span>
    ),
  };
  const profileNav: NavigationProps = {
    route: `${routes.profil}`,
    title: (
      <span>
        <IconMenuPatient id="icon-menu-profile" /> {menu.prof}
      </span>
    ),
  };
  const messagesNav: NavigationProps = {
    onClick: () => dispatch(refresh()),
    route: `${routes.spravy}`,
    title: (
      <span>
        <IconMail color="#BED2F4" id="icon-menu-mail" /> {menu.msg}
      </span>
    ),
  };

  if (isASOC)
    /** ASOC */
    return (
      <Navigation
        items={[
          dashboardNav,
          {
            route: routes.zmluvyDodatky,
            title: (
              <span>
                <IconMenuContracts id="icon-menu-contracts" /> {menu.zml}
              </span>
            ),
          },
          profileNav,
          messagesNav,
        ]}
        mobileOpen={isOpen}
        onClose={() => dispatch(toggleMenu())}
      />
    );
  return (
    <Navigation
      items={[
        dashboardNav,
        ...(hasSection(EPSections.Zuc, 'Zuctovanie') ||
        hasSection(EPSections.Fkt, 'Zuctovanie')
          ? [
              {
                hasChildren: true,
                onClick: () =>
                  dispatch(toggleItem(MenuItemDropdown.zuctovanie)),
                route: '',
                title: (
                  <span>
                    <IconMoneyInsert id="icon-menu-money-insert" /> {menu.zuc}
                  </span>
                ),
                isOpen: openedItems.zuctovanie,
                items: [
                  ...(hasSection(EPSections.Zuc, 'Zuctovanie')
                    ? [
                        {
                          route: routes.odoslanieDavok,
                          title: submenu.sendBatches,
                        },
                        {
                          route: routes.prehladDavok,
                          title: submenu.batchPreview,
                        },
                      ]
                    : []),
                  {
                    route: routes.faktury,
                    title: 'Faktúry',
                  },
                  ...(hasSection(EPSections.DispZaz, 'Zuctovanie')
                    ? [
                        {
                          onClick: () => {
                            window.location.href =
                              epSekcie['dispenzarizovani-poistenci'];
                          },
                          title: submenu.disp,
                        },
                      ]
                    : []),
                ],
              },
            ]
          : []),
        ...(hasSection(EPSections.Hosp, 'CakacieListiny')
          ? [
              {
                hasChildren: true,
                onClick: () => dispatch(toggleItem(MenuItemDropdown.cakacky)),
                route: '',
                title: (
                  <span>
                    <IconHourglasses color="#BED2F4" id="icon-menu-waiting" />{' '}
                    {menu.cl}
                  </span>
                ),
                isOpen: openedItems['cakacie-listiny'],
                items: [
                  {
                    route: routes.cakackyOdoslanieDavok,
                    title: submenu.sendBatches,
                  },
                  {
                    route: routes.cakackyPrehladDavok,
                    title: submenu.batchPreview,
                  },
                ],
              },
            ]
          : []),
        ...(userHasSection('ZMLUVY') && !!odbornosti?.length
          ? [
              {
                route: `${routes.manazerskyPrehladZmluv}`,
                title: (
                  <span>
                    <IconCalc id="icon-menu-pcp" /> {menu.pcp}
                  </span>
                ),
              },
              {
                hasChildren: true,
                onClick: () => dispatch(toggleItem(MenuItemDropdown.zmluvy)),
                route: '',
                title: (
                  <span>
                    <IconEServices id="icon-menu-eservices" /> {menu.zml}
                  </span>
                ),
                isOpen: openedItems.zmluvy,
                items: [
                  {
                    route: routes.zmluvyDodatky,
                    title: submenu.contracts,
                  },
                ],
              },
            ]
          : []),
        ...(userHasSection('PZSHPKEI')
          ? [
              {
                hasChildren: true,
                onClick: () => dispatch(toggleItem(MenuItemDropdown.parametre)),
                route: '',
                title: (
                  <span>
                    <IconMenuParameters id="icon-menu-parameters" />{' '}
                    {menu.parameters}
                  </span>
                ),
                isOpen: openedItems.parametre,
                items: [
                  {
                    route: routes.parameters,
                    title: submenu.parametersPreview,
                  },
                  ...(getParametersMenuItems(
                    menuParameters.groups ?? [],
                    aktivnaOdbornost,
                  )
                    ?.filter((p) => !p.title.includes('Špeciálne'))
                    ?.map((m) => ({
                      hasChildren: true,
                      isOpen:
                        openedItems[convertMenuItemFromGroupTitle(m.title)],
                      items: m.items?.map((item) => ({
                        route: item.href,
                        title: item.title,
                        onClick: item.onClick,
                      })),
                      route: '',
                      title: (
                        <span className="text-color-white">
                          {menuIconHP(m.title)}
                          {m.title}
                        </span>
                      ),
                      withoutAngle: false,
                      onClick: () =>
                        dispatch(
                          toggleItem(convertMenuItemFromGroupTitle(m.title)),
                        ),
                    })) || []),
                ],
              },
            ]
          : []),
        {
          hasChildren: true,
          isNotVisible:
            !hasSection(EPSections.Ln, 'Navrhy') &&
            !hasSection(EPSections.Kn, 'Navrhy'),
          onClick: () => dispatch(toggleItem(MenuItemDropdown.navrhy)),
          route: '',
          title: (
            <span>
              <IconNewMessage id="icon-new-message" /> {menu.kn}
            </span>
          ),
          isOpen: openedItems.navrhy,
          items: [
            {
              isNotVisible: !newProposalAccess(),
              route: navrhyRoutes.novyNavrh,
              title: submenu.newProposal,
            },
            {
              isNotVisible:
                !hasSection(EPSections.Ln, 'Navrhy') &&
                !hasSection(EPSections.Kn, 'Navrhy'),
              route: navrhyRoutes.zoznamPodanychNavrhov,
              title: submenu.proposalList,
            },
            {
              isNotVisible: !hasSection(EPSections.Nkup, 'Navrhy'),
              route: navrhyRoutes.zoznamSchvalenychNavrhov,
              title: submenu.approvedPorposalList,
            },
            {
              isNotVisible: !hasSection(EPSections.Cnp, 'Navrhy'),
              onClick: () => {
                window.location.href = epSekcie['produkty-na-sklade'];
              },
              title: submenu.productsOnStock,
            },
            {
              isNotVisible: !hasSection(EPSections.Cnp, 'Navrhy'),
              onClick: () => {
                window.location.href = epSekcie['ziadost-o-presun'];
              },
              title: submenu.moveRequest,
            },
            {
              isNotVisible: !hasSection(EPSections.Cnp, 'Navrhy'),
              onClick: () => {
                window.location.href = epSekcie['prehlad-objednavok'];
              },
              title: submenu.ordersPreview,
            },
          ],
        },
        {
          hasChildren: true,
          isNotVisible: !hasPZSNAKL && !userHasSection('KAP'),
          onClick: () => dispatch(toggleItem(MenuItemDropdown.pacienti)),
          route: '',
          title: (
            <span>
              <IconUsers color="#BED2F4" id="icon-menu-patients" /> {menu.pac}
            </span>
          ),
          isOpen: openedItems.pacienti,
          items: [
            ...(userHasSection('KAP') && canShowSection('KAP')
              ? [
                  {
                    route: routes.sporniPoistenci,
                    title: submenu.kap,
                  },
                ]
              : []),
            {
              isNotVisible: !hasPZSNAKL,
              hasChildren: location.pathname.includes(routes.pp),
              onClick: () =>
                dispatch(toggleItem(MenuItemDropdown.zdravotnaStarostlivost)),
              route: routes.pp,
              title: submenu.zsPreview,
              isOpen: openedItems['zdravotna-starostlivost'],
              items: pacientiRoutes,
            },
          ],
        },
        {
          hasChildren: false,
          isNotVisible: !hasPZSNAKL,
          title: (
            <span>
              <IconDoctor id="icon-menu-healthcare" /> {menu.diag}
            </span>
          ),
          route: diagnozyRoutes.index,
        },
        profileNav,
        messagesNav,
      ]}
      mobileOpen={isOpen}
      onClose={() => dispatch(toggleMenu())}
    />
  );
};

export default Menu;
