import { Button, Notification } from '@dovera/design-system';
import strings from '../../../../../../../constants/strings';
import SafeHtml from '../../../../../../../components/SafeHtml/SafeHtml';
import { useModalContext } from '../../../../../../../hooks/useModalContext';
import { ModalType } from '../../../../../../../types/modal.types';
import { useCallback } from 'react';
import { DrugProduct } from '../../../../../../../types/drugProposals.types';

const texts = strings.proposals.drugs.new.recommendedProducts;

interface Props {
  onSelect: (code: string, label: string) => void;
  products: DrugProduct[];
}

const RecommendedNtf = ({ onSelect, products }: Props) => {
  const modalContext = useModalContext();
  const chooseProduct = useCallback(
    (code: string, label: string) => {
      onSelect(code, label);
      modalContext.closeModal(ModalType.DP_RECOMMENDED);
    },
    [modalContext, onSelect],
  );
  const renderBtn = (
    <Button
      className="no-mrg"
      onClick={() =>
        modalContext.showModal(ModalType.DP_RECOMMENDED, {
          items: products?.map((p) => ({
            label: p.nazovProduktPzs,
            value: p.kodProduktPzs,
          })),
          onSelect: chooseProduct,
          type: 'products',
        })
      }
    >
      Pozrieť odporúčané náhrady
    </Button>
  );
  return (
    <Notification
      message={
        <div>
          <SafeHtml className="mb-small" html={texts.ntf} wrapper="div" />
          {renderBtn}
        </div>
      }
      variant="blue"
    />
  );
};

export default RecommendedNtf;
