import {
  Button,
  ButtonLayout,
  ButtonLink,
  Grid,
  GridCol,
  Icon,
  color,
} from '@dovera/design-system';
import { GetProposalDetailResponse } from '../../../../types/spaProposals.types';
import strings from '../../../../constants/strings';
import { cx } from '../../../../utils/exports';
import { formatDate } from '../../../../utils/date.utils';
import useStyles from '../../Proposals.styles';
import { getProposalState } from '../../../../utils/spaProposals.utils';
import { getListFromString } from '../../../../utils/strings.utils';

interface Props {
  data: GetProposalDetailResponse;
  isApproved: boolean;
  onReservationClick?: (type: 'create' | 'update' | 'delete') => void;
  pin?: string | null;
}

type InfoRowType = {
  icon: 'success' | 'error';
  isLabelBold?: boolean;
  label: string;
  value: string;
};

const texts = strings.proposals.spa.detail;

const ProposalHeader = ({
  data,
  isApproved,
  onReservationClick,
  pin,
}: Props) => {
  const classes = useStyles({ proposalType: 'KN' });
  const { detail } = data;
  const proposalPin = detail.pin || pin;
  const renderInfoRow = ({ icon, isLabelBold, label, value }: InfoRowType) => (
    <div className={cx(classes.proposalHeaderRow, 'mb-small')}>
      {icon === 'error' ? (
        <Icon color={color('error', 600)} name="reject" size="medium" />
      ) : (
        <Icon color={color('primary')} name="check" size="medium" />
      )}
      <span className={cx('text-space-half-right', isLabelBold && 'text-bold')}>
        {`${label}:`}
      </span>
      <span>{value}</span>
    </div>
  );
  const renderActions =
    onReservationClick &&
    (detail.rezervaciaNavrhu.id ? (
      <ButtonLayout
        className={cx('mb-small', classes.proposalDetailButtons)}
        direction="horizontal"
      >
        {detail.rezervaciaNavrhu.upravitRezervaciu && (
          <ButtonLink
            className="no-mrg"
            onClick={() => onReservationClick('update')}
          >
            <Icon
              className="text-space-half-right"
              name="16-edit"
              size="medium"
            />
            {texts.buttons.edit}
          </ButtonLink>
        )}
        {detail.rezervaciaNavrhu.zmazatRezervaciu && (
          <ButtonLink
            className="no-mrg text-color-error"
            onClick={() => onReservationClick('delete')}
          >
            {texts.buttons.delete}
          </ButtonLink>
        )}
      </ButtonLayout>
    ) : (
      detail.rezervaciaNavrhu.zadatRezervaciu && (
        <Button className="no-mrg" onClick={() => onReservationClick('create')}>
          <Icon color="white" name="16-calendar" size="medium" />
          <span className="text-space-left">{texts.buttons.create}</span>
        </Button>
      )
    ));
  return (
    <Grid>
      <GridCol className="mb" size={6}>
        {getProposalState(detail.stav, classes, true)}
        <div className="mb-small" />
        {renderInfoRow({
          icon: detail.vystaveny ? 'success' : 'error',
          label: texts.labels.exposed(detail.typVystavenia),
          value: detail.vystaveny
            ? formatDate(detail.vystaveny)
            : strings.undefined,
        })}
        {renderInfoRow({
          icon: detail.prijaty ? 'success' : 'error',
          label: texts.labels.accepted,
          value: detail.prijaty
            ? formatDate(detail.prijaty)
            : strings.undefined,
        })}
        {renderInfoRow({
          icon: detail.rozhodnuty ? 'success' : 'error',
          label: texts.labels.resultDate,
          value: detail.rozhodnuty
            ? formatDate(detail.rozhodnuty)
            : strings.undefined,
        })}
        {renderInfoRow({
          icon: detail.platnostNavrhuOd ? 'success' : 'error',
          label: texts.labels.validity,
          value: `${formatDate(detail.platnostNavrhuOd || '')} - ${formatDate(
            detail.platnostNavrhuDo || '',
          )}`,
        })}
        {detail.rezervaciaNavrhu.rezervaciaKupelov &&
          renderInfoRow({
            icon: 'success',
            label: texts.labels.reservation,
            value: detail.rezervaciaNavrhu.rezervaciaKupelov,
          })}
        {isApproved && renderActions}
      </GridCol>
      <GridCol className="mb" size={6}>
        {proposalPin && (
          <div className="d-flex mb-small">
            <Icon name="key" size="medium" />
            <b className="text-space-half-left text-space-half-right">{`${texts.labels.pin}: `}</b>
            {proposalPin}
          </div>
        )}
        <b className="d-block">{`${texts.labels.insuranceStatemenet}:`}</b>
        {!detail.vyjadreniePoistovne && strings.undefined}
        <ul className={classes.statementPoints}>
          {getListFromString(detail?.vyjadreniePoistovne || '').map(
            (d, key) => (
              <li key={`insuranceStatement--point--${key}`}>{d}</li>
            ),
          )}
        </ul>
        <b>{`${texts.labels.treatment}: `}</b>
        {detail.kupelnaLiecba || strings.undefined}
      </GridCol>
    </Grid>
  );
};

export default ProposalHeader;
