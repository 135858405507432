import { ReactNode } from 'react';
import { PROPOSAL_TYPES } from '../types/proposals.types';
import SPAStepper from '../pages/proposals/NewProposal/SpaProposal/Stepper';
import DrugsStepper from '../pages/proposals/NewProposal/DrugProposal/Stepper';
import strings from '../constants/strings';
import store from '../store';
import { resetNewProposalSpaForm } from '../slices/spaProposals.slice';
import { resetNewProposalDrugForm } from '../slices/drugProposals.slice';
import { spaProposalsInitialState } from '../constants/proposals';
import { drugProposalsInitialState } from '../constants/drugProposals';
import { history } from '../helpers/history';
import { hasSection } from './poskytovatel.utils';
import { EPSections } from '../constants/systemConstants';

export const newProposalAccess = (): boolean =>
  hasSection(EPSections.Kn, 'Navrhy') || hasSection(EPSections.Ln, 'Navrhy');

export const getNewProposalContent = (
  choosed?: PROPOSAL_TYPES | string,
): ReactNode | null => {
  if (choosed)
    return choosed === PROPOSAL_TYPES.SPA ? <SPAStepper /> : <DrugsStepper />;
  if (
    !hasSection(EPSections.Kn, 'Navrhy') &&
    hasSection(EPSections.Ln, 'Navrhy')
  )
    return <DrugsStepper />;
  if (
    hasSection(EPSections.Kn, 'Navrhy') &&
    !hasSection(EPSections.Ln, 'Navrhy')
  )
    return <SPAStepper />;
  return null;
};

export const getProposalResult = ({
  error,
  isCnp,
  isTechnicalError,
  proposalNumber,
  type,
}: {
  error?: string | null;
  isCnp?: boolean;
  isTechnicalError?: boolean;
  proposalNumber?: string | null;
  type: 'KN' | 'LN';
}): {
  description: string;
  primaryBtn: string;
  primaryBtnRoute?: string;
  secondaryBtn?: string;
  status: 'success' | 'warning' | 'error' | null;
  title: string;
} => {
  const knTexts = strings.proposals.spa.new.result;
  const lnTexts = strings.proposals.drugs.new.result;
  if (type === 'KN') {
    if (isTechnicalError)
      return {
        description: knTexts.warning.description,
        primaryBtn: knTexts.warning.btn,
        secondaryBtn: knTexts.warning.secondaryBtn,
        status: 'warning',
        title: knTexts.warning.title(proposalNumber),
      };
    if (error)
      return {
        description: knTexts.error.description,
        primaryBtn: knTexts.error.btn,
        status: 'error',
        title: knTexts.error.title(proposalNumber),
      };
    return {
      description: knTexts.success.description,
      primaryBtn: knTexts.success.btn,
      secondaryBtn: knTexts.success.secondaryBtn,
      status: 'success',
      title: knTexts.success.title(proposalNumber),
    };
  }
  if (isTechnicalError)
    return {
      description: lnTexts.warning.description,
      primaryBtn: lnTexts.warning.primaryBtn.text,
      status: 'warning',
      title: lnTexts.warning.title,
    };
  if (error)
    return {
      description: lnTexts.error.description,
      primaryBtn: lnTexts.error.primaryBtn.text,
      primaryBtnRoute: lnTexts.error.primaryBtn.route,
      status: 'error',
      secondaryBtn: lnTexts.error.secondaryBtn,
      title: lnTexts.error.title,
    };
  if (isCnp)
    return {
      description: lnTexts.success.cnp.description,
      primaryBtn: lnTexts.success.cnp.primaryBtn.text,
      primaryBtnRoute: lnTexts.success.cnp.primaryBtn.route,
      status: 'success',
      secondaryBtn: lnTexts.success.cnp.secondaryBtn,
      title: lnTexts.success.cnp.title(proposalNumber),
    };
  return {
    description: lnTexts.success.drug.description,
    primaryBtn: lnTexts.success.drug.primaryBtn.text,
    primaryBtnRoute: lnTexts.success.drug.primaryBtn.route,
    status: 'success',
    secondaryBtn: lnTexts.success.drug.secondaryBtn,
    title: lnTexts.success.drug.title(proposalNumber),
  };
};

export const resetNewProposal = (): void => {
  const globalState = store?.getState();
  if (
    !history.location?.search &&
    (globalState?.spaProposals.new.save !== spaProposalsInitialState.new.save ||
      globalState?.drugProposals.new.save !==
        drugProposalsInitialState.new.save)
  ) {
    store?.dispatch(resetNewProposalSpaForm());
    store?.dispatch(resetNewProposalDrugForm());
  }
};
